import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Waypoint from 'react-waypoint';

import { deemazerShape } from '../../utils/prop_shapes';
import customFetch from '../../utils/network_utils';
import Gallery from '../Gallery';

const teamWrapperRef = React.createRef();

export default class TeamGallery extends Component {
  state = {
    listOfFacts: [],
    description: 'love pizza',
    randomFactId: -1,
    firstFrame: 0,
    animationProgress: 0,
    galleryStickedPosition: 0,
    firstAnimationStarted: false,
  };

  tick = false;

  componentDidMount() {
    this.getDeemazerFacts();
  }

  async getDeemazerFacts() {
    const listOfFacts = await customFetch('/api/deemazer_facts');
    this.setState({ listOfFacts });
  }

  addVisibility = () => {
    window.addEventListener('scroll', this.handleThrottleScroll, true);
  };

  removeVisibility = () => {
    window.removeEventListener('scroll', this.handleThrottleScroll, true);
  };

  handleThrottleScroll = () => {
    if (this.tick) return this;
    this.tick = !this.tick;
    this.handleProgress();
    return this;
  };

  onClickRandomFact = () => {
    const { listOfFacts, randomFactId } = this.state;

    if (randomFactId === listOfFacts.length - 1) {
      this.setState({
        randomFactId: 0,
        description: listOfFacts[0].description,
      });
    } else {
      this.setState({
        randomFactId: randomFactId + 1,
        description: listOfFacts[randomFactId + 1].description,
      });
    }
  };

  handleProgress() {
    const { galleryStickedPosition, firstFrame, firstAnimationStarted } =
      this.state;
    const teamWrapper = teamWrapperRef.current;
    const distance = teamWrapper.getBoundingClientRect().top;

    if (distance !== galleryStickedPosition && !firstAnimationStarted) {
      this.setState({
        galleryStickedPosition: distance,
        firstFrame: document.documentElement.scrollTop,
      });
    } else {
      let currentProgress =
        -10 + (100 * (document.documentElement.scrollTop - firstFrame)) / 250;
      currentProgress = Math.min(100, Math.max(0, currentProgress));

      this.setState({
        animationProgress: currentProgress,
        firstAnimationStarted: true,
      });
    }
    this.tick = !this.tick;
    return this;
  }

  renderText = () => {
    const { description, animationProgress } = this.state;
    const { deemazers } = this.props;
    const paragraphStyle = {
      transform: `translateY(${10 - animationProgress / 10}px)`,
      opacity: animationProgress / 100,
      visibility: animationProgress > 0 ? 'visible' : 'hidden',
    };

    return (
      <div className="team">
        <h2>Our people</h2>
        <p>
          Deemaze was founded in 2015 by three nice dudes. Meet João, Paulo and
          António.
        </p>
        <p style={paragraphStyle}>
          {`Soon, we started to grow and we are now a team of ${deemazers.length} developers and designers.`}
        </p>
        <p style={paragraphStyle}>
          And we
          <button
            type="button"
            className="randomizer"
            onClick={this.onClickRandomFact}
          >
            {description}
          </button>
          .
        </p>
      </div>
    );
  };

  render() {
    const { deemazers } = this.props;
    const { listOfFacts, randomFactId, animationProgress } = this.state;

    return (
      <div className="team-gallery-wrapper full-width">
        <div className="content-wrapper" ref={teamWrapperRef}>
          <Waypoint
            onEnter={this.addVisibility}
            onLeave={this.removeVisibility}
            bottomOffset="20%"
          >
            <div>
              {this.renderText()}
              <Gallery
                deemazers={deemazers}
                listOfFacts={listOfFacts}
                randomFactId={randomFactId}
                animationProgress={animationProgress}
              />
            </div>
          </Waypoint>
        </div>
        <span className="scroll-block" />
      </div>
    );
  }
}

TeamGallery.propTypes = {
  deemazers: PropTypes.arrayOf(deemazerShape),
};
