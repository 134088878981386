import React from 'react';
import PropTypes from 'prop-types';

import ProjectInfo from '../ProjectInfo';
import ProjectHeader from '../ProjectHeader';
import EZFXBrokerDescription from '../EZFXBrokerDescription';
import EZFXBrokerBranding from '../EZFXBrokerBranding';
import ProjectGallery, { GALLERY_NUMBER } from '../ProjectGallery';
import { projectsShape } from '../../utils/prop_shapes';
import SiteMetadata from '../SiteMetadata';

import ezfxQuoteAcceptance from '../../assets/projects/ezfxbroker/ezfx_quote_acceptance.svg';
import ezfxStep1 from '../../assets/projects/ezfxbroker/ezfx_step1.svg';
import ezfxStep1Mobile from '../../assets/projects/ezfxbroker/ezfx_step1_mobile.svg';
import ezfxStep2 from '../../assets/projects/ezfxbroker/ezfx_step2.svg';
import ezfxStep3 from '../../assets/projects/ezfxbroker/ezfx_step3.svg';
import logoDesign from '../../assets/projects/ezfxbroker/logo_design.svg';
import logoDesignMobile from '../../assets/projects/ezfxbroker/logo_design_mobile.svg';
import ezfxClientProblem from '../../assets/projects/ezfxbroker/ezfx_client_problem.svg';
import ezfxKnowProduct from '../../assets/projects/ezfxbroker/ezfx_know_product.svg';
import ezfxCoverWhite from '../../assets/projects/ezfxbroker/ezfx_cover_white.svg';

const KNOW_MORE_SECTION = [
  {
    description:
      'Thanks to the unique digital confirmation, it is possible to reduce instances of mis-selling.\n\nThe clients have the possibility to choose one of the suggested solutions and after select and confirm it, it gets autommaticaly approved by them.',
  },
];

const KNOW_MORE_PHOTOS = [
  {
    source: ezfxQuoteAcceptance,
    alt: 'List of projects',
  },
];

const STEPS_PHOTOS = [
  {
    source: ezfxStep1,
    alt: 'List of projects',
  },
  {
    source: ezfxStep1Mobile,
    alt: 'List of projects',
  },
  {
    source: ezfxStep2,
    alt: 'List of projects',
  },
  {
    source: ezfxStep3,
    alt: 'List of projects',
  },
];

// meta data props
const baseMetadata = {
  description:
    'EZFX Broker is a unique platform that allows brokers and banks to easily create a well-presented hedging solution to a client.',
  siteUrl: 'https://deemaze.com/projects/ezfxbroker',
  sitePrefix: 'EZFXBroker',
};

const EZFX_INTRODUCTION = [
  {
    title: 'Our role',
    description:
      "We've been involved in this project since the very beginning. Starting with the first ideas, going through the product structure and finally the visual product, ready to be used by a client. We had a big input on the visual design of the platform as well as some decisions made during the process.",
    image: {
      alt: 'Image with EZFX Broker default logo and variants.',
      source: logoDesign,
    },
    image_mobile: logoDesignMobile,
  },
];

const EZFX_BRANDING = {
  title: 'Visual Design',
  description:
    "This platform was designed with a subtle grey palette with little touches of green and purple to create some contrast. \n\n All the colors, typography and logo choices are based on Assure Hedge’s visual identity because the main requirement was to make it easily recognizable for their clients, as an AH's product.",
};

const EZFX_PALETTE = [
  {
    name: 'lightgray',
    hex_code: '#f1f4f8',
  },
  {
    name: 'gray',
    hex_code: '#dde4ee',
  },
  {
    name: 'green',
    hex_code: '#01b68c',
  },
  {
    name: 'darkgreen',
    hex_code: '#439c79',
  },
  {
    name: 'purple',
    hex_code: '#583a96',
  },
  {
    name: 'darkpurple',
    hex_code: '#432c72',
  },
  {
    name: 'darkgray',
    hex_code: '#6b7c94',
  },
  {
    name: 'darkergray',
    hex_code: '#465161',
  },
];

const GALLERY_TEXT = [
  {
    title: 'Know The Client’s Problem',
    description:
      "With an adaptive questionary it's simpler to understand the client's economic situation that allows to know the costumers and their needs.",
  },
];

const GALLERY_PHOTOS = [
  {
    source: ezfxClientProblem,
    alt: 'List of projects',
  },
];

const SECOND_GALLERY_TEXT = [
  {
    title: 'Know The Product',
    description:
      'The quote creator contains straightforward questions and useful tooltips explaining any technical terms, which helps the broker communicate with the clients and clarify every step and any doubts that could possibly occur during the process.',
  },
  {
    title: 'Other benefits',
    description: {
      first:
        "Bespoke term sheets can be created instantly following the client's needs guided by live market prices.",
      second:
        'Multiple solutions can be added to the one-term sheet and the customer is able to choose the one that adapts better to his needs.',
    },
  },
];

const SECOND_GALLERY_PHOTOS = [
  {
    source: ezfxKnowProduct,
    alt: 'List of projects',
  },
];

const EZFXBroker = ({ project }) => (
  <main className="ezfxbroker-wrapper full-width">
    <SiteMetadata
      {...baseMetadata}
      keywords={project.keywords}
      social={project.assets.social ? project.assets.social : ''}
    />
    <ProjectHeader project={project} coverImageUrl={ezfxCoverWhite} whiteText />
    <EZFXBrokerDescription ezfxIntroduction={EZFX_INTRODUCTION} />
    <EZFXBrokerBranding
      brandingContent={EZFX_BRANDING}
      palette={EZFX_PALETTE}
    />
    <ProjectGallery
      galleryPhotos={GALLERY_PHOTOS}
      projectName={project.name_slug}
      galleryText={GALLERY_TEXT}
      galleryNumber={GALLERY_NUMBER.clientProblem}
    />
    <ProjectGallery
      galleryPhotos={SECOND_GALLERY_PHOTOS}
      projectName={project.name_slug}
      galleryText={SECOND_GALLERY_TEXT}
      galleryNumber={GALLERY_NUMBER.knowProduct}
    />
    <ProjectGallery
      galleryPhotos={KNOW_MORE_PHOTOS}
      extraPhotos={STEPS_PHOTOS}
      projectName={project.name_slug}
      galleryText={KNOW_MORE_SECTION}
      galleryNumber={GALLERY_NUMBER.solutions}
    />
    <ProjectInfo
      project={project}
      madeWith={project.made_with}
      listOfTasks={project.our_role}
      linkToProject={project.link}
    />
  </main>
);

export default EZFXBroker;

EZFXBroker.propTypes = {
  project: PropTypes.shape(projectsShape),
};
