import React from 'react';

import icon1 from '../../assets/projects/brythm/brythm_icon_1.svg';
import icon2 from '../../assets/projects/brythm/brythm_icon_2.svg';
import icon3 from '../../assets/projects/brythm/brythm_icon_3.svg';
import sensorMobile1 from '../../assets/projects/brythm/sensor_mobile_1.svg';
import sensorMobile2 from '../../assets/projects/brythm/sensor_mobile_2.svg';
import sensorDesktop from '../../assets/projects/brythm/sensor_desktop.svg';
import brythmSession from '../../assets/projects/brythm/session.mp4';

const coreFeatures = () => {
  const FEATURES = [
    {
      icon: icon1,
      label: 'Session progress',
    },
    {
      icon: icon2,
      label: 'Haptic feedback',
    },
    {
      icon: icon3,
      label: 'Audio feedback',
    },
  ];

  return (
    <React.Fragment>
      {FEATURES.map((feature, index) => (
        <li key={`feature-${index}`}>
          <img src={feature.icon} alt="" />
          <p>{feature.label}</p>
        </li>
      ))}
    </React.Fragment>
  );
};

const BrythmSession = () => (
  <section className="bg-light full-width">
    <div className="session-wrapper">
      <video
        className="session"
        autoPlay
        playsInline
        muted
        loop
        src={brythmSession}
      />
      <img className="cable" src={sensorMobile1} alt="" />
      <div className="session-description">
        <h2>Daily training sessions, in your pocket</h2>
        <p className="gray">
          Take a 10-minute session each day. The breathing speed is adjusted for
          you in real-time, using the data collected by the sensor.
        </p>
        <ul className="features-list">{coreFeatures()}</ul>
      </div>
      <img className="sensor" src={sensorMobile2} alt="Sensor for finger." />
      <img
        className="full-sensor"
        src={sensorDesktop}
        alt="Sensor for finger."
      />
    </div>
  </section>
);

export default BrythmSession;
