import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import NavList from '../NavList';
import Hamburguer from '../Hamburguer';
import Menu from '../Menu';
import { projectsShape } from '../../utils/prop_shapes';
import DeemazeLogo from '../DeemazeLogo';

const HEADER_OPTIONS = {
  projects: ['Everything', 'iOS', 'Android', 'Web', 'Design'],
  about: ['Who', 'How', "What's new"],
  menu: ['Projects', 'Services', 'About', 'Careers', 'Contacts'],
  empty: [],
};

const BLACK_HEADER_PAGES = [
  '/projects/coolfarm',
  '/projects/binderstudio',
  '/contacts',
  '/services',
  '/projects',
  '/careers',
  '/about',
];

const EMPTY_NAV_PAGES = [
  'projects/binderstudio',
  'projects/storytrail',
  'projects/iampop',
  'projects/brythm',
  'projects/devicelab',
  'projects/ezfxbroker',
  'careers',
  'contacts',
  'services',
  '',
];

const PROJECTS_PAGES = [
  'binderstudio',
  'storytrail',
  'iampop',
  'brythm',
  'devicelab',
  'ezfxbroker',
];

const verifyOptionValue = (option) =>
  HEADER_OPTIONS.projects.includes(option) ||
  HEADER_OPTIONS.about.includes(option);

export default class Header extends Component {
  state = {
    headerOptions: [],
    toggleMenu: false,
    firstClick: false,
    cancelActiveIndex: false,
  };

  componentDidUpdate() {
    this.getLocation();
  }

  getLocation = () => {
    const { pathname } = window.location;
    const { currentPath, setCurrentPath, selectProjectType } = this.props;

    if (currentPath !== pathname) {
      document.documentElement.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant',
      });

      setCurrentPath(pathname);
      selectProjectType('everything');
      this.handleNavOptions();
    }
  };

  handleNavOptions = () => {
    const { setProjectPage } = this.props;
    const { pathname } = window.location;

    let path = pathname.slice(1);
    let isProjectPage = false;
    let currentProjectSlug = '';

    if (EMPTY_NAV_PAGES.includes(path)) {
      currentProjectSlug = path.replace('projects/', '');
      if (PROJECTS_PAGES.includes(currentProjectSlug)) {
        isProjectPage = true;
      }
      path = 'empty';
    }

    setProjectPage(isProjectPage);
    const headerOptions = HEADER_OPTIONS[path.toLowerCase()] || [];
    this.setState({ headerOptions });
  };

  handleColor = () => {
    const { toggleMenu } = this.state;
    const { currentPath } = this.props;
    if (BLACK_HEADER_PAGES.includes(currentPath) && !toggleMenu) {
      return false;
    }
    return true;
  };

  setDefaultHeaderOptions = () => {
    const { setProjectPage } = this.props;
    setProjectPage(false);
    this.setState({
      headerOptions: HEADER_OPTIONS.empty,
      toggleMenu: false,
    });
  };

  setOptionAndVerifyValue = (option) => {
    const { selectProjectType } = this.props;

    if (verifyOptionValue(option)) {
      selectProjectType(option.toLowerCase());
    } else {
      this.setState((prevState) => ({
        toggleMenu: !prevState.toggleMenu,
      }));
      if (EMPTY_NAV_PAGES.includes(option.toLowerCase())) {
        this.setState({ headerOptions: HEADER_OPTIONS.empty });
      } else {
        this.setState({ headerOptions: HEADER_OPTIONS[option.toLowerCase()] });
      }
    }
  };

  menuClick = () => {
    const { toggleMenu } = this.state;
    this.setState({ firstClick: true });

    if (toggleMenu) {
      this.setState((prevState) => ({
        toggleMenu: !prevState.toggleMenu,
      }));
    } else {
      this.setState({
        toggleMenu: true,
        cancelActiveIndex: true,
      });
    }
  };

  setProjectsHeaderOptions = () => {
    const { setProjectPage } = this.props;
    this.setState((prevState) => ({
      toggleMenu: !prevState.toggleMenu,
      headerOptions: [],
    }));
    setProjectPage(true);
  };

  render() {
    const { headerOptions, toggleMenu, firstClick, cancelActiveIndex } =
      this.state;

    const { projects, hasScrolledProjectHeader } = this.props;
    const headerClass = classNames('header', {
      'menu-open': toggleMenu,
    });

    return (
      <React.Fragment>
        <header className={headerClass}>
          <Link
            to="/"
            className="link-home"
            onClick={this.setDefaultHeaderOptions}
          >
            <DeemazeLogo color={this.handleColor() ? 'white' : 'black'} />
          </Link>
          {headerOptions.length >= 0 && !toggleMenu && (
            <NavList
              headerOptions={headerOptions}
              toggleMenu={toggleMenu}
              setOptionAndVerifyValue={this.setOptionAndVerifyValue}
              verifyOptionValue={verifyOptionValue}
              cancelActiveIndex={cancelActiveIndex}
            />
          )}
          <button
            type="button"
            className="hamburguer-wrapper"
            onClick={this.menuClick}
          >
            <Hamburguer
              toggleMenu={toggleMenu}
              whiteburguer={this.handleColor()}
              hasScrolledProjectHeader={hasScrolledProjectHeader}
            />
          </button>
        </header>
        <Menu
          toggleMenu={toggleMenu}
          projects={projects}
          firstClick={firstClick}
          setProjectsHeaderOptions={this.setProjectsHeaderOptions}
          headerOptions={HEADER_OPTIONS.menu}
          setOptionAndVerifyValue={this.setOptionAndVerifyValue}
          verifyOptionValue={verifyOptionValue}
          cancelActiveIndex={cancelActiveIndex}
        />
      </React.Fragment>
    );
  }
}

Header.propTypes = {
  projects: PropTypes.arrayOf(projectsShape),
  selectProjectType: PropTypes.func.isRequired,
  hasScrolledProjectHeader: PropTypes.bool.isRequired,
  setProjectPage: PropTypes.func.isRequired,
  setCurrentPath: PropTypes.func.isRequired,
  currentPath: PropTypes.string.isRequired,
};
