import React from 'react';
import PropTypes from 'prop-types';

import ProjectHeader from '../ProjectHeader';
import StoryTrailDescription from '../StoryTrailDescription';
import ProjectInfo from '../ProjectInfo';
import { projectsShape } from '../../utils/prop_shapes';
import ProjectGallery from '../ProjectGallery';
import SiteMetadata from '../SiteMetadata';

import st0Video from '../../assets/projects/storytrail/storytrail_0.mp4';
import st0Placeholder from '../../assets/projects/storytrail/storytrail_0.jpg';
import st1Video from '../../assets/projects/storytrail/storytrail_1.mp4';
import st1Placeholder from '../../assets/projects/storytrail/storytrail_1.jpg';
import st2Video from '../../assets/projects/storytrail/storytrail_2.mp4';
import st2Placeholder from '../../assets/projects/storytrail/storytrail_2.jpg';
import st3Video from '../../assets/projects/storytrail/storytrail_3.mp4';
import st3Placeholder from '../../assets/projects/storytrail/storytrail_3.jpg';
import tablet0 from '../../assets/projects/storytrail/tablet_0.jpg';
import tablet1 from '../../assets/projects/storytrail/tablet_1.jpg';
import tablet2 from '../../assets/projects/storytrail/tablet_2.jpg';
import stCover from '../../assets/projects/storytrail/cover_storytrail.svg';

// meta data props
const baseMetadata = {
  description:
    'Your mobile friend to help you explore cities, discovering stories and unexpected paths.',
  siteUrl: 'https://deemaze.com/projects/storytrail',
  sitePrefix: 'Storytrail',
};

const GALLERY_TEXT = [
  {
    title: 'Your holidays, your devices.',
    description: 'Use Storytrail on your iPad for an equally great experience.',
  },
];

// Props to pass on to project description
const FEATURES_INFORMATION = [
  {
    title: 'Explore cities',
    description:
      'Storytrail helps you explore big cities and discover curious stories by organizing your holiday destination in trails!',
    columnNumber: 1,
    video: st0Video,
    placeholder: st0Placeholder,
  },
  {
    title: 'Choose your trail',
    description:
      "Each city has several walking trails with a video guide at each stop. You'll never feel lost or bored!",
    columnNumber: 2,
    video: st1Video,
    placeholder: st1Placeholder,
  },
  {
    title: 'Build your journey',
    description:
      "If you don't have much time or are a selective traveler, you can make your trails shorter by filtering the stories by your favorite categories.",
    columnNumber: 1,
    video: st2Video,
    placeholder: st2Placeholder,
  },
  {
    title: 'No internet? No problem!',
    description:
      'Download the content of your favorite trails so you can use the app offline.',
    video: st3Video,
    placeholder: st3Placeholder,
    columnNumber: 2,
  },
];

const galleryPhotos = [
  {
    source: tablet0,
    alt: 'Map view with videos on each stop.',
  },
  {
    source: tablet1,
    alt: 'Menu layout and Rome preview.',
  },
  {
    source: tablet2,
    alt: 'Page with list of cities.',
  },
];

const StoryTrail = ({ project }) => (
  <main className="storytrail-wrapper full-width">
    <SiteMetadata
      {...baseMetadata}
      keywords={project.keywords}
      social={project.assets.social ? project.assets.social : ''}
    />
    <ProjectHeader project={project} coverImageUrl={stCover} whiteText />
    <StoryTrailDescription featuresInformation={FEATURES_INFORMATION} />
    <ProjectGallery
      galleryPhotos={galleryPhotos}
      projectName={project.name_slug}
      galleryText={GALLERY_TEXT}
    />
    <ProjectInfo
      project={project}
      madeWith={project.made_with}
      listOfTasks={project.our_role}
      linkToProject={project.link}
    />
  </main>
);

export default StoryTrail;

StoryTrail.propTypes = {
  project: PropTypes.shape(projectsShape),
};
