import React from 'react';
import PropTypes from 'prop-types';

import { imageShape } from '../../utils/prop_shapes';

const FindUs = ({ title, addresses: { coimbra, braga, martingny }, image }) => (
  <section className="find-us-at full-width bg-light">
    <div className="image-wrapper full-width">
      <img className="full-width" src={image.source} alt={image.alt} />
    </div>
    <div className="text-wrapper">
      <div className="title">
        <h2>{title[0]}</h2>
        <h2 className="gray">{title[1]}</h2>
      </div>
      <h3>Portuguese offices</h3>
      <div className="address">
        <p className="gray">{coimbra.street}</p>
        <p className="gray">{coimbra.postcode}</p>
        <p className="gray">{coimbra.country}</p>
      </div>
      <div className="address">
        <p className="gray">{braga.street}</p>
        <p className="gray">{braga.postcode}</p>
        <p className="gray">{braga.country}</p>
      </div>
      <h3>Swiss office</h3>
      <div className="address">
        <p className="gray">{martingny.street}</p>
        <p className="gray">{martingny.postcode}</p>
        <p className="gray">{martingny.country}</p>
      </div>
    </div>
  </section>
);

export default FindUs;

FindUs.propTypes = {
  title: PropTypes.arrayOf(PropTypes.string),
  addresses: PropTypes.shape({
    coimbra: PropTypes.objectOf(PropTypes.string),
    braga: PropTypes.objectOf(PropTypes.string),
    martingny: PropTypes.objectOf(PropTypes.string),
  }),
  image: imageShape,
};
