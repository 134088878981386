import React from 'react';

import notFoundAsset from '../../assets/404.svg';

const NotFound = () => (
  <section className="404 full-width">
    <div className="the-maze">
      <h1>404</h1>
      <h2 className="gray">Whoops! You seem to be lost.</h2>
      <p>
        The page you’re trying to access is no longer here, or maybe it never
        even existed. Unless you were looking for our 404 page.
      </p>
    </div>
    <div className="full-width-media full-width">
      <img className="full-width" src={notFoundAsset} alt="" />
    </div>
  </section>
);

export default NotFound;
