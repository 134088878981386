import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import LinkCardImages from '../LinkCardImages';

const LinkCard = ({ type }) => (
  <Link to={`/${type}`} href className={`link-card ${type}`}>
    <LinkCardImages type={type} />
    <p>{type}</p>
  </Link>
);

export default LinkCard;

LinkCard.propTypes = {
  type: PropTypes.string.isRequired,
};
