import React from 'react';
import classNames from 'classnames';

const LandingSection = () => {
  // FIXME: This is a temporary fix, while we can't make the animation run
  // smoothly accross all browsers
  const isChrome =
    !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
  const isIE = /* @cc_on!@ */ false || !!document.documentMode;
  const isEdge = !isIE && !!window.StyleMedia;
  const isSafari =
    /constructor/i.test(window.HTMLElement) ||
    (function (p) {
      return p.toString() === '[object SafariRemoteNotification]';
    })(
      !window.safari ||
        (typeof safari !== 'undefined' && window.safari.pushNotification),
    );

  const landingClass = classNames('landing full-width', {
    'animate-background': isChrome || isSafari || isEdge,
  });

  return (
    <div className={landingClass}>
      <div className="title-wrapper">
        <h1>
          <span>Hi.</span>
        </h1>
        <h1>
          <span>This is Deemaze,</span>
          <span>a software agency</span>
          <span>building digital</span>
          <span>products and services.</span>
        </h1>
      </div>
    </div>
  );
};

export default LandingSection;
