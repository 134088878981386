import React from 'react';

import CallToAction from '../CallToAction';
import DeemazeProcess from '../DeemazeProcess';
import ServicesList from '../ServicesList';
import ServicesIntro from '../ServicesIntro';
import SiteMetadata from '../SiteMetadata';

import ogPreview from '../../assets/og_preview.png';
import services from '../../assets/services.svg';

// meta data props
const metadata = {
  keywords: [
    'Deemaze',
    'Deemaze Software',
    'Digital Product Development',
    'Digital Services Devlopment',
    'App Development',
    'Product Development',
    'Software Devolpment',
    'Apps',
    'Mobile App',
    'Mobile App Development',
    'Web',
    'Web Design',
    'Software',
    'iOS',
    'Android',
    'Website',
    'build an MVP',
    'Services',
  ],
  description:
    'This is Deemaze, a software agency focused on building digital products and services.',
  siteUrl: 'https://deemaze.com/services',
  sitePrefix: 'Services',
  social: ogPreview,
};

const SERVICES_INTRO = {
  title: 'Welcome.',
  subtitle: 'How may we serve you?',
  description:
    "Entrusting your beloved project to a new team is never easy, but we're here to make it as smooth as possible. " +
    "Get acquainted with what we do, and reach out when you're ready to meet us. " +
    "Together, we'll craft a strategy tailored to your needs.",
  image: {
    source: services,
    alt: 'Website illustration',
  },
};

const CALL_TO_ACTION_TEXT = {
  title: "Let's talk!",
  subtitle: 'Ready when you are.',
  description:
    'You have an idea but are not sure where to begin? Your business is thriving and you’re ready to boost it to the next level? ' +
    'It’s time to send us a message.',
};

const DEEMAZE_PROCESS_TEXT = [
  {
    title: 'Transparent communication',
    text:
      'We believe communication is key to a successful partnership, so we always keep you in the loop. ' +
      'All input is taken into consideration, and everything is discussed and decided with you.',
  },
  {
    title: 'Deliver → Evaluate',
    text:
      'We like working with you. This means we’re always evaluating how your business can evolve and experience healthy growth. ' +
      'Whenever we reach a goal, we establish new ones, together.',
  },
  {
    title: 'Test, test, test',
    text:
      'Our design and development process always involves tests performed by our ' +
      'team and in sessions with real users. This way, there are no nasty surprises later on.',
  },
  {
    title: 'Support',
    text:
      'Keeping your business on the right track is our rule of thumb. ' +
      'We take care of making your product go live, and make sure everything keeps on the right track, providing any maintenance or assistance you may need.',
  },
];

const DEEMAZE_PROCESS_TITLE = {
  title: 'We have some rules.',
  title_gray: 'This is how we work.',
};

const SERVICES_LIST_TEXTS = [
  {
    service: 'Design',
    description:
      'We help you develop your idea from early concept to high fidelity prototypes. ' +
      'If you’re still figuring out the details, we start by identifying business opportunities by ' +
      'outlining digital services that resolve specific needs. ' +
      'Then we design delightful web and mobile experiences for all your users.',
    subservices: ['User Interface', 'User Experience', 'Product Design'],
  },
  {
    service: 'Software',
    description:
      'We have a large experience in development, thus ensuring scalable and secure ' +
      'systems for real-world projects, using whatever tools and technologies that best fit ' +
      'the product. We walk alongside you from the very start, and help publish and maintain your product.',
    subservices: ['Mobile development', 'Web development'],
  },
  {
    service: 'Marketing',
    description:
      'Your product is ready for the world — now we make ' +
      'sure the world sees it. We make a plan for advertising your business, ' +
      'while collecting data that provides insights on how to improve it.',
    subservices: [
      'Advertising',
      'Digital media strategy',
      'Analytics and optimization',
    ],
  },
  {
    service: 'Branding',
    description:
      'We team up with you to create a brand that has a strong message and ' +
      'a unique identity. Whether you need to refresh your brand’s image or build ' +
      'one from scratch, we make sure you communicate your vision while standing out from your competitors.',
    subservices: [
      'Brand identity',
      'Brand development strategy',
      'Voice and tone',
    ],
  },
];

const Services = () => (
  <main className="full-width">
    <SiteMetadata {...metadata} />
    <ServicesIntro content={SERVICES_INTRO} />
    <ServicesList servicesList={SERVICES_LIST_TEXTS} />
    <DeemazeProcess
      processTexts={DEEMAZE_PROCESS_TEXT}
      processTitle={DEEMAZE_PROCESS_TITLE}
    />
    <CallToAction
      background="white"
      text={CALL_TO_ACTION_TEXT}
      types={['contacts']}
    />
  </main>
);

export default Services;
