import React, { Component } from 'react';
import Waypoint from 'react-waypoint';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class CareerPerks extends Component {
  state = { animate: false };

  startAnimation = () => {
    this.setState({ animate: true });
  };

  render() {
    const { perks } = this.props;
    const midpoint = Math.ceil(perks.length / 2);

    const { animate } = this.state;
    const perkListClasses = classNames('perk-list', {
      'loading-animation': animate,
    });

    return (
      <section className="career-perks section-padding full-width bg-light">
        <div className="title-wrapper">
          <h2>There is more!</h2>
          <h2 className="gray">Here are some perks of joining us.</h2>
        </div>
        <Waypoint onEnter={this.startAnimation} bottomOffset="30%">
          <div className={perkListClasses}>
            <div className="text-wrapper">
              <ul>
                {perks.slice(0, midpoint).map((perk) => (
                  <li key={perk.id}>{perk.description}</li>
                ))}
              </ul>
            </div>
            <div className="text-wrapper">
              <ul>
                {perks.slice(midpoint).map((perk) => (
                  <li key={perk.id}>{perk.description}</li>
                ))}
              </ul>
            </div>
          </div>
        </Waypoint>
      </section>
    );
  }
}

CareerPerks.propTypes = {
  perks: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
    }),
  ),
};
