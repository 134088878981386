import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Waypoint from 'react-waypoint';
import classNames from 'classnames';

import { paletteShape } from '../../utils/prop_shapes';

export default class EZFXBrokerPalette extends Component {
  state = {
    startAnimating: false,
  };

  startAnimation = () => this.setState({ startAnimating: true });

  render() {
    const { palette } = this.props;
    const { startAnimating } = this.state;
    const paletteWrapper = classNames('ezfx-palette', {
      'loading-animation': startAnimating,
    });
    return (
      <Waypoint onEnter={this.startAnimation} bottomOffset="20%">
        <div className={paletteWrapper}>
          {palette.map((color) => (
            <div className={color.name} key={color.hex_code}>
              <span>{color.hex_code}</span>
            </div>
          ))}
        </div>
      </Waypoint>
    );
  }
}

EZFXBrokerPalette.propTypes = {
  palette: PropTypes.arrayOf(paletteShape).isRequired,
};
