import React from 'react';
import PropTypes from 'prop-types';

import ProjectHeader from '../ProjectHeader';
import ProjectInfo from '../ProjectInfo';
import ProjectGallery, { GALLERY_NUMBER } from '../ProjectGallery';
import { projectsShape } from '../../utils/prop_shapes';
import WhyBinder from '../WhyBinder';
import ProductIntroduction from '../ProductIntroduction';
import DesktopShowcase from '../DesktopShowcase';
import BinderInspiration from '../BinderInspiration';
import SiteMetadata from '../SiteMetadata';

import teamwork1 from '../../assets/projects/binder/teamwork_1.svg';
import teamwork2 from '../../assets/projects/binder/teamwork_2.svg';
import tracks from '../../assets/projects/binder/tracks.svg';
import collabs1 from '../../assets/projects/binder/collabs_1.svg';
import collabs2 from '../../assets/projects/binder/collabs_2.svg';
import collabs3 from '../../assets/projects/binder/collabs_3.svg';
import collabs4 from '../../assets/projects/binder/collabs_4.svg';
import mobile1 from '../../assets/projects/binder/mobile_1.svg';
import mobile2 from '../../assets/projects/binder/mobile_2.svg';
import mobile3 from '../../assets/projects/binder/mobile_3.svg';
import cover from '../../assets/projects/binder/cover_binder.jpg';

// meta data props
const baseMetadata = {
  description:
    'Binder connects musicians from all around the world, allowing you to create music remotely.',
  siteUrl: 'https://deemaze.com/projects/binderstudio',
  sitePrefix: 'Binder Studio',
};

const GALLERY_PHOTOS = [
  {
    source: teamwork1,
    alt: 'List of projects',
  },
  {
    source: teamwork2,
    alt: 'Project overview: description and members.',
  },
];

const BINDER_INTRODUCTION = [
  {
    title: 'The reality',
    description:
      'Musicians all over the world are now able to produce music without needing an expensive studio, as computer based solutions are becoming better and more affordable.',
  },
  {
    title: 'Problem',
    description:
      'However, amateur artists struggle to produce music in collaboration with other musicians. In a time where most things can be done remotely, they still rely on cloud services that are not tailored for their specific needs.',
  },
  {
    title: 'Solution',
    description:
      'We developed a collaborative platform, that allows musicians all over the world to produce music together. It’s like Github, but for music!',
  },
  {
    title: 'The extra mile',
    description:
      'In addition to the project management tool, we dedicated a section of the platform to the community. Project owners can open applications for their tracks so other musicians can apply. If you have the skill, but not the crew, it’s time to find new projects and collaborators!',
  },
];

const GALLERY_TEXT = [
  {
    title: 'Teamwork',
    description:
      'Each song is an individual project, in which you can organize the tracks by instrument. Add team members and start creating!',
  },
];

const DESKTOP_SECTION = {
  title: 'Track your tracks',
  description:
    'Binder provides version control for all updates made to a track. We identify the modification, who made it, and keep the older versions available in case you need them later.',
  image: {
    alt: 'Screen with detailed track history.',
    source: tracks,
  },
};

const SECOND_GALLERY_TEXT = [
  {
    title: 'Build a community',
    description:
      'Look for new music projects to join, or open a track application and wait for the perfect musician to apply to your project.',
  },
];

const SECOND_GALLERY_PHOTOS = [
  {
    source: collabs1,
    alt: 'Filter collaborations by instrument.',
  },
  {
    source: collabs2,
    alt: 'List of pending submissions to open collaborations.',
  },
  {
    source: collabs3,
    alt: 'List of open collaborations, with number of submissions.',
  },
  {
    source: collabs4,
    alt: 'List of submissions made to a project.',
  },
];

// Binder inspiration Component related variables
const INSPIRATION_INTRO = {
  title: 'Inspiration is everywhere',
  description:
    'On mobile, you can do everything and more. Let the music guide you, not the device.',
};

const INSPIRATION_SECTIONS = [
  {
    title: 'Record new audio files',
    description:
      'We have equipped Binder for Android with the tools you need to quickly record anything.',
    image: {
      source: mobile1,
      alt: 'Layout to configure metronome and start recording.',
    },
  },
  {
    title: 'Edit your tracks',
    description:
      'You can edit each track’s volume and panning, which gives you a better vision of your final version.',
    image: {
      source: mobile2,
      alt: 'Layout to edit a single track using sliders.',
    },
  },
  {
    title: 'Get notified',
    description:
      'Check the status of your submissions, and get warnings when someone applies to work with you.',
    image: {
      source: mobile3,
      alt: 'List of projects looking for collaborators.',
    },
  },
];

const BinderStudio = ({ project }) => (
  <main className="binder-studio-wrapper full-width">
    <SiteMetadata
      {...baseMetadata}
      keywords={project.keywords}
      social={project.assets.social ? project.assets.social : ''}
    />
    <ProjectHeader project={project} coverImageUrl={cover} whiteText={false} />
    <ProductIntroduction
      productName={project.name}
      productIntroduction={BINDER_INTRODUCTION}
    />
    <WhyBinder />
    <ProjectGallery
      galleryPhotos={GALLERY_PHOTOS}
      projectName={project.name_slug}
      galleryText={GALLERY_TEXT}
      galleryNumber={GALLERY_NUMBER.teamWork}
    />
    <DesktopShowcase desktopSection={DESKTOP_SECTION} />
    <ProjectGallery
      galleryPhotos={SECOND_GALLERY_PHOTOS}
      projectName={project.name_slug}
      galleryText={SECOND_GALLERY_TEXT}
      galleryNumber={GALLERY_NUMBER.collabs}
    />
    <BinderInspiration
      inspirationIntro={INSPIRATION_INTRO}
      inspirationSections={INSPIRATION_SECTIONS}
    />
    <ProjectInfo
      project={project}
      madeWith={project.made_with}
      listOfTasks={project.our_role}
      linkToProject={project.link}
    />
  </main>
);
export default BinderStudio;

BinderStudio.propTypes = {
  project: PropTypes.shape(projectsShape),
};
