import React from 'react';
import PropTypes from 'prop-types';

import { imageShape } from '../../utils/prop_shapes';
import TextWrapper from '../TextWrapper';

const ServicesIntro = ({ content }) => (
  <section className="section-padding services-intro">
    <TextWrapper text={content} largeTitle largeWrapper />
    <img src={content.image.source} alt={content.image.alt} />
  </section>
);

export default ServicesIntro;

ServicesIntro.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: imageShape,
  }),
};
