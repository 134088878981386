import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Waypoint from 'react-waypoint';
import classNames from 'classnames';

import { imageShape } from '../../utils/prop_shapes';
import TextWrapper from '../TextWrapper';

export default class DeviceLabPhotos extends Component {
  state = {
    startAnimating: false,
  };

  startAnimation = () => this.setState({ startAnimating: true });

  render() {
    const { copy, photos } = this.props;
    const { startAnimating } = this.state;
    const photosWrapper = classNames('photos-wrapper', {
      'loading-animation': startAnimating,
    });
    return (
      <section className="devicelab-photos full-width">
        <TextWrapper text={copy} />
        <Waypoint onEnter={this.startAnimation} bottomOffset="30%">
          <div className="full-width">
            <div className={photosWrapper}>
              {photos.map((photo, index) => (
                <img src={photo.source} alt={photo.alt} key={index} />
              ))}
            </div>
          </div>
        </Waypoint>
        <span className="separator" />
      </section>
    );
  }
}

DeviceLabPhotos.propTypes = {
  copy: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }),
  photos: PropTypes.arrayOf(imageShape).isRequired,
};
