import React, { Component } from 'react';
import Waypoint from 'react-waypoint';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export default class DeemazeProcess extends Component {
  state = {
    startAnimating: false,
  };

  startAnimation = () => this.setState({ startAnimating: true });

  render() {
    const { processTexts, processTitle } = this.props;
    const { startAnimating } = this.state;

    const processesWrapper = classNames('processes-wrapper', {
      'loading-animation': startAnimating,
    });

    return (
      <section className="deemaze-process section-padding bg-light full-width">
        <div className="title-wrapper">
          <h2>{processTitle.title}</h2>
          <h2 className="gray">{processTitle.title_gray}</h2>
        </div>
        <Waypoint onEnter={this.startAnimation} bottomOffset="30%">
          <div className={processesWrapper}>
            {processTexts.map((process, index) => (
              <div className="text-wrapper" key={index}>
                <h3>{process.title}</h3>
                <p>{process.text}</p>
              </div>
            ))}
          </div>
        </Waypoint>
      </section>
    );
  }
}

DeemazeProcess.propTypes = {
  processTexts: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string,
    }),
  ),
  processTitle: PropTypes.shape({
    title: PropTypes.string,
    title_gray: PropTypes.string,
  }),
};
