import React, { Component } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import { ProjectHeaderRefConsumer } from '../ProjectHeaderRefContext';
import { projectsShape } from '../../utils/prop_shapes';

export default class ProjectHeader extends Component {
  state = {
    currentProject: [],
  };

  renderPhoto = () => {
    const { coverImageUrl } = this.props;
    return <img src={coverImageUrl} className="full-width" alt="" />;
  };

  renderHeaderLayout = (project) => {
    const { whiteText } = this.props;
    const whiteFontedHeader = classNames('', {
      white: whiteText,
    });

    return (
      <React.Fragment>
        <ProjectHeaderRefConsumer>
          {(value) => (
            <div
              className={`project-cover-wrapper full-width ${project.name_slug}`}
            >
              <div className="project-introduction" ref={value}>
                <h1 className={whiteFontedHeader}>{project.name}</h1>
                <p className={whiteFontedHeader}>{project.description}</p>
              </div>
              <div className="image-wrapper full-width">
                {this.renderPhoto()}
              </div>
            </div>
          )}
        </ProjectHeaderRefConsumer>
      </React.Fragment>
    );
  };

  render() {
    const { project } = this.props;
    const { currentProject } = this.state;

    return (
      <React.Fragment>
        {this.renderHeaderLayout(project || currentProject)}
      </React.Fragment>
    );
  }
}

ProjectHeader.propTypes = {
  project: PropTypes.shape(projectsShape),
  coverImageUrl: PropTypes.string,
  whiteText: PropTypes.bool,
};
