import React, { useEffect, useState } from 'react';

import SiteMetadata from '../SiteMetadata';
import CareerIntro from '../CareerIntro';
import CareerPerks from '../CareerPerks';
import CareerOpening from '../CareerOpening';
import ContactForm from '../ContactForm';
import customFetch from '../../utils/network_utils';

import ogPreview from '../../assets/og_preview.png';
import careersCover from '../../assets/careers_cover.svg';
import careersEmpty from '../../assets/careers_empty.svg';

const metadata = {
  keywords: [
    'Deemaze',
    'Deemaze Software',
    'Digital Product Development',
    'Digital Services Devlopment',
    'App Development',
    'Product Development',
    'Software Devolpment',
    'Apps',
    'Mobile App',
    'Mobile App Development',
    'Web',
    'Web Design',
    'Software',
    'iOS',
    'Android',
    'Website',
    'build an MVP',
    'About',
  ],
  description:
    'Check out the opportunities we have open and apply to join our amazing team!',
  siteUrl: 'https://deemaze.com/careers',
  sitePrefix: 'Careers',
  social: ogPreview,
};

const Careers = () => {
  const [jobOpenings, setJobOpenings] = useState([]);
  const [collaboratorsCount, setCollaboratorsCount] = useState(0);
  const [perks, setPerks] = useState([]);

  const INTRO = {
    title: 'Careers.',
    subtitle: 'Join us!',
    description: `
    Right now we have a very diverse team, with ${collaboratorsCount} collaborators, and we’re always looking for new Deemazers. Our team is focused on delivering their best work and learning every day how to do that, solving problems, and coming up with some innovative and out-of-the-box ideas.

    If you’re interested in joining us, check out some of our current job openings bellow.
  `,
    image: {
      source: careersCover,
      alt: 'Careers illustration',
    },
  };

  useEffect(() => {
    const fetchJobOpenings = async () => {
      const { jobs, meta } = await customFetch('/api/job_openings');
      const { collaborators_count: collaboratorsTotal } = meta;
      const openingsWithImg = jobs.map((opening) => ({
        ...opening,
        image: {
          source: require(`../../assets/careers/maze-${opening.id % 10}.svg`),
          alt: 'Job opening illustration',
        },
      }));
      setJobOpenings(openingsWithImg);
      setCollaboratorsCount(collaboratorsTotal);
    };

    const fetchPerks = async () => {
      const response = await customFetch('/api/perks');
      setPerks(response);
    };

    fetchJobOpenings();
    fetchPerks();
  }, []);

  if (!collaboratorsCount) return null;

  return (
    <main className="careers full-width">
      <SiteMetadata {...metadata} />
      <CareerIntro content={INTRO} />
      {jobOpenings.length ? (
        <section className="career-opening-wrapper">
          {jobOpenings.map((opening) => (
            <CareerOpening opening={opening} key={opening.id} />
          ))}
        </section>
      ) : (
        <section className="section-padding careers-empty">
          <img src={careersEmpty} alt="No openings illustration" />
          <h1 className="small">No opening currently available</h1>
          <p className="gray">
            Still interested in knowing us better? Drop us an email at{' '}
            <span className="email">moc&#x2024;ezameed@ofni</span>
          </p>
        </section>
      )}
      <CareerPerks perks={perks} />
      <h1 className="small" id="contact-us">
        Interested? Apply now!
      </h1>
      <p className="gray">
        Fill the form or email us at{' '}
        <span className="email">moc&#x2024;ezameed@sreerac</span>
      </p>
      <ContactForm />
    </main>
  );
};

export default Careers;
