import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const BinderLogo = ({ isAnimating }) => {
  const logoClass = classNames('binder-logo', {
    'is-animating': isAnimating,
  });

  return (
    <svg
      version="1.1"
      width="250px"
      height="70px"
      viewBox="0 0 250 70"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={logoClass}
    >
      <title>Binder Studio</title>
      <desc>Created with Sketch.</desc>
      <defs>
        <path
          id="e"
          d="m114.47 32.999c1.5946 0.43715 2.8493 1.2279 3.7642 2.3721 0.91492 1.1443 1.3724 2.5907 1.3724 4.3393 0 2.34-0.8953 4.14-2.6859 5.4s-4.3981 1.89-7.8225 1.89h-13.096v-27h12.312c3.1369 0 5.5614 0.61714 7.2736 1.8514 1.7122 1.2343 2.5683 2.9443 2.5683 5.13 0 1.4143-0.33329 2.6357-0.99987 3.6643-0.66658 1.0286-1.5619 1.8129-2.6859 2.3529zm-14.547-9.8743v8.64h8.0382c2.0128 0 3.5616-0.36642 4.6465-1.0993s1.6272-1.8064 1.6272-3.2207-0.54241-2.4879-1.6272-3.2207-2.6336-1.0993-4.6465-1.0993h-8.0382zm9.0185 20.751c2.2219 0 3.8949-0.36 5.019-1.08 1.124-0.72 1.6861-1.8514 1.6861-3.3943 0-3.0086-2.235-4.5129-6.705-4.5129h-9.0185v8.9871h9.0185zm16.351-23.876h3.9211v27h-3.9211v-27zm35.368 0v27h-3.2153l-16.468-20.134v20.134h-3.9211v-27h3.2153l16.468 20.134v-20.134h3.9211zm7.8421 0h11.567c2.9016 0 5.4764 0.56571 7.7245 1.6971 2.2481 1.1314 3.9929 2.7193 5.2346 4.7636s1.8625 4.3907 1.8625 7.0393-0.62083 4.995-1.8625 7.0393-2.9865 3.6321-5.2346 4.7636c-2.2481 1.1314-4.8229 1.6971-7.7245 1.6971h-11.567v-27zm11.332 23.644c2.2219 0 4.1759-0.42428 5.862-1.2729 1.6861-0.84858 2.9865-2.0378 3.9015-3.5679 0.91492-1.53 1.3724-3.2978 1.3724-5.3036s-0.45745-3.7736-1.3724-5.3036c-0.91492-1.53-2.2154-2.7193-3.9015-3.5679-1.6861-0.84858-3.64-1.2729-5.862-1.2729h-7.4108v20.289h7.4108zm40.583 0v3.3557h-19.919v-27h19.37v3.3557h-15.449v8.2929h13.763v3.2786h-13.763v8.7171h15.998zm24.311 3.3557l-5.9208-8.2929c-0.73194 0.051429-1.307 0.077143-1.7253 0.077143h-6.7834v8.2157h-3.9211v-27h10.705c3.5551 0 6.3521 0.83571 8.3911 2.5071 2.039 1.6714 3.0584 3.9728 3.0584 6.9043 0 2.0829-0.5228 3.8571-1.5684 5.3229-1.0456 1.4657-2.5356 2.5329-4.47 3.2014l6.509 9.0643h-4.274zm-7.7637-11.494c2.4834 0 4.3785-0.52714 5.6855-1.5814 1.307-1.0543 1.9605-2.5586 1.9605-4.5129s-0.6535-3.4521-1.9605-4.4936c-1.307-1.0414-3.2022-1.5621-5.6855-1.5621h-6.6658v12.15h6.6658z"
        />
        <path
          id="c"
          d="m11.595 0.41118c-2.3573 0.97629-10.159 5.8621-11.595 23.641 3.012 0.2529 7.9239 0.90925 10.373-1.1394 8.4277-7.0511 9.7243-13.431 20.745-18.131-10.096-4.1226-16.604-5.5795-19.523-4.3705z"
        />
        <filter id="g" x="-19.3%" y="-24.6%" width="138.6%" height="149.3%">
          <feGaussianBlur
            in="SourceAlpha"
            result="shadowBlurInner1"
            stdDeviation="3.5"
          />
          <feOffset
            dx="3"
            dy="3"
            in="shadowBlurInner1"
            result="shadowOffsetInner1"
          />
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner1"
          />
          <feColorMatrix
            in="shadowInnerInner1"
            result="shadowMatrixInner1"
            values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 0.6 0"
          />
          <feGaussianBlur
            in="SourceAlpha"
            result="shadowBlurInner2"
            stdDeviation="3"
          />
          <feOffset
            dx="0"
            dy="-6"
            in="shadowBlurInner2"
            result="shadowOffsetInner2"
          />
          <feComposite
            in="shadowOffsetInner2"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner2"
          />
          <feColorMatrix
            in="shadowInnerInner2"
            result="shadowMatrixInner2"
            values="0 0 0 0 1   0 0 0 0 0.82745098   0 0 0 0 0  0 0 0 1 0"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixInner1" />
            <feMergeNode in="shadowMatrixInner2" />
          </feMerge>
        </filter>
        <path
          id="b"
          d="m35.334 6.9792c-11.346 4.0085-11.346 12.025-23.223 19.592-3.869 2.4649-8.8905 1.3778-11.903 1.1262-0.15573 1.9187-0.22892 2.6207-0.20282 4.783 0.011372 0.94229 0.039081 0.52748 0.08193 1.4281 4.0273 1.242 8.7376 2.2685 14.032 0.6592 13.553-4.1197 19.615-13.333 39.999-16.961-7.4837-4.7875-13.745-8.33-18.784-10.627z"
        />
        <filter id="f" x="-12.9%" y="-24.6%" width="125.9%" height="149.3%">
          <feGaussianBlur
            in="SourceAlpha"
            result="shadowBlurInner1"
            stdDeviation="3.5"
          />
          <feOffset
            dx="6"
            dy="3"
            in="shadowBlurInner1"
            result="shadowOffsetInner1"
          />
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner1"
          />
          <feColorMatrix
            in="shadowInnerInner1"
            result="shadowMatrixInner1"
            values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 0.6 0"
          />
          <feGaussianBlur
            in="SourceAlpha"
            result="shadowBlurInner2"
            stdDeviation="4"
          />
          <feOffset
            dx="0"
            dy="-6"
            in="shadowBlurInner2"
            result="shadowOffsetInner2"
          />
          <feComposite
            in="shadowOffsetInner2"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner2"
          />
          <feColorMatrix
            in="shadowInnerInner2"
            result="shadowMatrixInner2"
            values="0 0 0 0 1   0 0 0 0 0.82745098   0 0 0 0 0  0 0 0 1 0"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixInner1" />
            <feMergeNode in="shadowMatrixInner2" />
          </feMerge>
        </filter>
        <path
          id="a"
          d="m68.992 34.057c0.010871-0.082442 0.010871-0.7331 0-0.81554-0.34221-3.6883-3.4625-6.418-11.037-12.002-20.589 3.5474-29.377 13.644-43.548 17.309-5.3329 1.3795-10.339 0.56114-14.407-0.65319 0.32743 3.5925 0.97003 6.6629 1.6372 9.4712 7.7231-2.2985 18.918-4.0506 32.655-1.6932 7.8997 1.3556 12.83 3.7931 14.769 6.6871 11.024-6.8579 19.487-13.528 19.93-18.303z"
        />
        <filter id="i" x="-13%" y="-28.9%" width="126.1%" height="157.8%">
          <feGaussianBlur
            in="SourceAlpha"
            result="shadowBlurInner1"
            stdDeviation="3.5"
          />
          <feOffset
            dx="6"
            dy="3"
            in="shadowBlurInner1"
            result="shadowOffsetInner1"
          />
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner1"
          />
          <feColorMatrix
            in="shadowInnerInner1"
            result="shadowMatrixInner1"
            values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 0.6 0"
          />
          <feGaussianBlur
            in="SourceAlpha"
            result="shadowBlurInner2"
            stdDeviation="5"
          />
          <feOffset
            dx="0"
            dy="-8"
            in="shadowBlurInner2"
            result="shadowOffsetInner2"
          />
          <feComposite
            in="shadowOffsetInner2"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner2"
          />
          <feColorMatrix
            in="shadowInnerInner2"
            result="shadowMatrixInner2"
            values="0 0 0 0 1   0 0 0 0 0.82745098   0 0 0 0 0  0 0 0 0.8 0"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixInner1" />
            <feMergeNode in="shadowMatrixInner2" />
          </feMerge>
        </filter>
        <path
          id="d"
          d="m12.657 66.799c-1.9368-0.70872-6.8195-3.668-9.8686-15.306 7.7086-2.2748 18.367-4.0561 28.207-2.3661 8.0655 1.3853 13.186 3.0303 15.087 6.0842-13.156 8.3633-26.502 14.121-33.425 11.588z"
        />
        <filter id="h" x="-16.2%" y="-37%" width="132.3%" height="173.9%">
          <feGaussianBlur
            in="SourceAlpha"
            result="shadowBlurInner1"
            stdDeviation="3.5"
          />
          <feOffset
            dx="0"
            dy="3"
            in="shadowBlurInner1"
            result="shadowOffsetInner1"
          />
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner1"
          />
          <feColorMatrix
            in="shadowInnerInner1"
            result="shadowMatrixInner1"
            values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 0.6 0"
          />
          <feGaussianBlur
            in="SourceAlpha"
            result="shadowBlurInner2"
            stdDeviation="4"
          />
          <feOffset
            dx="0"
            dy="-6"
            in="shadowBlurInner2"
            result="shadowOffsetInner2"
          />
          <feComposite
            in="shadowOffsetInner2"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner2"
          />
          <feColorMatrix
            in="shadowInnerInner2"
            result="shadowMatrixInner2"
            values="0 0 0 0 1   0 0 0 0 0.82745098   0 0 0 0 0  0 0 0 1 0"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixInner1" />
            <feMergeNode in="shadowMatrixInner2" />
          </feMerge>
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd">
        <g fillRule="nonzero" id="wordmark">
          <use fill="#13191C" xlinkHref="#e" />
          <use fill="#FFFFFF" xlinkHref="#e" />
        </g>
        <g transform="translate(1 1)" id="icon">
          <mask fill="white">
            <use xlinkHref="#c" />
          </mask>
          <use fill="#FEE94E" fillRule="evenodd" xlinkHref="#c" />
          <use fill="black" filter="url(#g)" xlinkHref="#c" />
          <mask fill="white">
            <use xlinkHref="#b" />
          </mask>
          <use fill="#FEE94E" fillRule="evenodd" xlinkHref="#b" />
          <use fill="black" filter="url(#f)" xlinkHref="#b" />
          <mask fill="white">
            <use xlinkHref="#a" />
          </mask>
          <use fill="#FEE94E" fillRule="evenodd" xlinkHref="#a" />
          <use fill="black" filter="url(#i)" xlinkHref="#a" />
          <mask fill="white">
            <use xlinkHref="#d" />
          </mask>
          <use fill="#FEE94E" fillRule="evenodd" xlinkHref="#d" />
          <use fill="black" filter="url(#h)" xlinkHref="#d" />
        </g>
      </g>
    </svg>
  );
};

export default BinderLogo;

BinderLogo.propTypes = {
  isAnimating: PropTypes.bool.isRequired,
};
