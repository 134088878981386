import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';
import { Link as ScrollTo } from 'react-scroll';

const SCROLL_OFFSETS = {
  How: -50,
  "What's new": 0,
  Who: -50,
};

export default class NavList extends Component {
  state = {
    activeIndex: 0,
  };

  componentDidUpdate() {
    window.onpopstate = () => {
      // remove selected project type when clicking browser's back button
      this.setState({ activeIndex: 0 });
    };
  }

  handleClick = (option, index) => () => {
    this.selectNavOption(option, index);
  };

  handleKeyDown = (option, index) => (event) => {
    if (event.keyCode === 13) {
      this.selectNavOption(option, index);
    }
  };

  selectNavOption(option, index) {
    const { setOptionAndVerifyValue } = this.props;
    this.setState({ activeIndex: index });
    setOptionAndVerifyValue(option);
    // this will reset the activeindex whenever an option from menu is chosen
    if (option === 'About' || option === 'Contacts') {
      this.setState({ activeIndex: -1 });
    }
  }

  filterLayout(option, index) {
    const { activeIndex } = this.state;
    const WHATS_NEW_VARIABLE = 'whatsnew';

    return (
      <ScrollTo
        to={option !== "What's new" ? option : WHATS_NEW_VARIABLE}
        duration={500}
        offset={SCROLL_OFFSETS[option]}
        smooth
        className={activeIndex === index ? 'active' : ''}
        onClick={this.handleClick(option, index)}
        onKeyDown={this.handleKeyDown(option, index)}
        tabIndex="0"
      >
        {option}
      </ScrollTo>
    );
  }

  linkToSectionsLayout(option, index) {
    const { activeIndex } = this.state;
    const { cancelActiveIndex } = this.props;
    return (
      <Link
        to={`/${option.toLowerCase()}`}
        className={activeIndex === index && !cancelActiveIndex ? 'active' : ''}
        onClick={this.handleClick(option, index)}
      >
        {option}
      </Link>
    );
  }

  render() {
    const { headerOptions, toggleMenu, verifyOptionValue, menuAnimationEnd } =
      this.props;
    const classname = classNames('menu-options', {
      'vertical-list': toggleMenu,
      'toggle-animation': menuAnimationEnd || !toggleMenu,
    });

    return (
      <React.Fragment>
        <ul className={classname}>
          {headerOptions.map((option, index) => (
            <li key={index}>
              {verifyOptionValue(option)
                ? this.filterLayout(option, index)
                : this.linkToSectionsLayout(option, index)}
            </li>
          ))}
        </ul>
      </React.Fragment>
    );
  }
}

NavList.propTypes = {
  headerOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  toggleMenu: PropTypes.bool.isRequired,
  setOptionAndVerifyValue: PropTypes.func.isRequired,
  verifyOptionValue: PropTypes.func.isRequired,
  cancelActiveIndex: PropTypes.bool.isRequired,
  menuAnimationEnd: PropTypes.bool,
};
