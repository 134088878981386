import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router';
import PropTypes from 'prop-types';

import { projectsShape } from '../../utils/prop_shapes';
import Homepage from '../Homepage';
import About from '../About';
import ProjectPreviewWrapper from '../ProjectPreviewWrapper';
import BinderStudio from '../BinderStudio';
import Brythm from '../Brythm';
import StoryTrail from '../StoryTrail';
import DeviceLab from '../DeviceLab';
import EZFXBroker from '../EZFXBroker';
import Pop from '../Pop';
import Careers from '../Careers';
import ContactUs from '../ContactUs';
import Services from '../Services';
import NotFound from '../NotFound';

const PROJECT_PATHS = [
  {
    path: '/projects/binderstudio',
    name: 'Binder Studio',
  },
  {
    path: '/projects/storytrail',
    name: 'Storytrail',
  },
  {
    path: '/projects/brythm',
    name: 'Brythm',
  },
  {
    path: '/projects/devicelab',
    name: 'Device Lab',
  },
  {
    path: '/projects/iampop',
    name: 'I AM POP',
  },
  {
    path: '/projects/ezfxbroker',
    name: 'EZFX Broker',
  },
];

export default class Main extends Component {
  renderProjectPreviewWrapper = () => {
    const { selectedType, projects } = this.props;
    return (
      <ProjectPreviewWrapper selectedType={selectedType} projects={projects} />
    );
  };

  findProject = (name) => {
    const { projects } = this.props;
    return projects.filter((project) => project.name === name)[0];
  };

  renderProject = (projectName) => () => {
    const projects = {
      'Binder Studio': BinderStudio,
      Brythm,
      'Device Lab': DeviceLab,
      'I AM POP': Pop,
      Storytrail: StoryTrail,
      'EZFX Broker': EZFXBroker,
    };
    const project = this.findProject(projectName);
    if (project) {
      const ProjectComponent = projects[projectName];
      return <ProjectComponent project={this.findProject(projectName)} />;
    }
    return null;
  };

  render() {
    const routeProjects = PROJECT_PATHS.map(({ path, name }) => (
      <Route key={name} path={path} exact render={this.renderProject(name)} />
    ));

    const routeProjectRedirects = PROJECT_PATHS.map(({ path, name }) => (
      <Redirect strict from={`${path}/`} to={path} key={name} />
    ));

    return (
      <Switch>
        <Route path="/" exact component={Homepage} />
        <Redirect strict from="/about/" to="/about" />
        <Route path="/about" exact component={About} />
        <Redirect strict exact from="/projects/" to="/projects" />
        {routeProjectRedirects}
        {routeProjects}
        <Route
          path="/projects"
          exact
          render={this.renderProjectPreviewWrapper}
        />
        <Redirect strict from="/careers/" to="/careers" />
        <Route path="/careers" exact component={Careers} />
        <Redirect strict from="/contacts/" to="/contacts" />
        <Route path="/contacts" exact component={ContactUs} />
        <Redirect strict from="/services/" to="/services" />
        <Route path="/services" exact component={Services} />
        <Route component={NotFound} />
      </Switch>
    );
  }
}

Main.propTypes = {
  projects: PropTypes.arrayOf(projectsShape),
  selectedType: PropTypes.string.isRequired,
};
