import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Waypoint from 'react-waypoint';
import classNames from 'classnames';

import { imageShape } from '../../utils/prop_shapes';
import TextWrapper from '../TextWrapper';

const SECOND_FEATURE_ID = 1;
const THIRD_FEATURE_ID = 2;

export default class PopDescription extends Component {
  state = {
    animateSecondText: false,
    animateThirdText: false,
  };

  startAnimation = (index) => () => {
    if (index === SECOND_FEATURE_ID) {
      this.setState({ animateSecondText: true });
    } else if (index === THIRD_FEATURE_ID) {
      this.setState({ animateThirdText: true });
    }
  };

  setImageClass = (index) => {
    const { animateSecondText, animateThirdText } = this.state;
    const imageClass = classNames('description-wrapper', {
      'animate-second': animateSecondText && index === SECOND_FEATURE_ID,
      'animate-third': animateThirdText && index === THIRD_FEATURE_ID,
    });

    return imageClass;
  };

  renderFirstColumn = (feature, index) => (
    <React.Fragment key={`feature-${index}`}>
      <div className={this.setImageClass(index)} key={feature.title}>
        <TextWrapper text={feature} />
      </div>
      <div className="overflow-wrapper full-width-mobile">
        <div className="image-wrapper">
          <img
            src={feature.image.source}
            className="mobile-hide"
            alt={feature.image.alt}
          />
          <img
            src={feature.image_mobile}
            className="desktop-hide"
            alt={feature.image.alt}
          />
        </div>
      </div>
    </React.Fragment>
  );

  renderSecondColumn = (feature, index) => {
    if (index === SECOND_FEATURE_ID || index === THIRD_FEATURE_ID) {
      return (
        <React.Fragment key={`feature-${index}`}>
          <Waypoint onEnter={this.startAnimation(index)} bottomOffset="40%">
            <img src={feature.image.source} alt={feature.image.alt} />
          </Waypoint>
        </React.Fragment>
      );
    }
    return (
      <img
        src={feature.image.source}
        alt={feature.image.alt}
        key={`img-${index}`}
      />
    );
  };

  render() {
    const { popIntroduction } = this.props;
    return (
      <section className="pop-features-section full-width">
        <div className="features-wrapper column-1-mobile full-width-mobile">
          {popIntroduction.map((feature, index) =>
            this.renderFirstColumn(feature, index),
          )}
        </div>
        <div className="grid-wrapper mobile-hide">
          <div className="features-wrapper column-1">
            {popIntroduction.map((feature, index) =>
              this.renderFirstColumn(feature, index),
            )}
          </div>
          <div className="features-wrapper column-2">
            {popIntroduction.map((feature, index) =>
              this.renderSecondColumn(feature, index),
            )}
          </div>
        </div>
      </section>
    );
  }
}

PopDescription.propTypes = {
  popIntroduction: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      image: imageShape.isRequired,
      image_mobile: PropTypes.string,
    }),
  ),
};
