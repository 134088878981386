import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Waypoint from 'react-waypoint';
import classNames from 'classnames';

import TextWrapper from '../TextWrapper';

export default class StoryTrailDescription extends Component {
  state = {
    startAnimating: false,
  };

  startAnimation = () => this.setState({ startAnimating: true });

  renderTextWithPhoto = (section, index) => (
    <React.Fragment key={`feature-${index}`}>
      <TextWrapper text={section} key={`feature-text-${index}`} />
      <Waypoint onEnter={this.startAnimation} bottomOffset="57%">
        <video
          autoPlay
          playsInline
          muted
          loop
          poster={section.placeholder}
          src={section.video}
        />
      </Waypoint>
    </React.Fragment>
  );

  render() {
    const { featuresInformation } = this.props;
    const { startAnimating } = this.state;
    const featureWrapper = classNames('feature-wrapper', {
      'loading-animation': startAnimating,
    });

    return (
      <React.Fragment>
        <div className={featureWrapper}>
          <div className="feature-list">
            {featuresInformation.map((section, index) => (
              <TextWrapper text={section} key={`feature-text-${index}`} />
            ))}
          </div>
          <div className="column-2">
            {featuresInformation.map(
              (section, index) =>
                section.columnNumber === 1 &&
                this.renderTextWithPhoto(section, index),
            )}
          </div>
          <div className="column-3">
            {featuresInformation.map(
              (section, index) =>
                section.columnNumber === 2 &&
                this.renderTextWithPhoto(section, index),
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

StoryTrailDescription.propTypes = {
  featuresInformation: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      columnNumber: PropTypes.number,
      path: PropTypes.string,
    }),
  ),
};
