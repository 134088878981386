export default [
  { link_type: 'twitter', link_url: 'https://twitter.com/deemazesoftware' },
  { link_type: 'medium', link_url: 'https://medium.com/deemaze-software' },
  {
    link_type: 'instagram',
    link_url: 'https://www.instagram.com/deemazesoftware/',
  },
  { link_type: 'github', link_url: 'https://github.com/deemaze' },
  { link_type: 'facebook', link_url: 'https://facebook.com/deemazesoftware' },
  {
    link_type: 'linkedin',
    link_url: 'https://www.linkedin.com/company/deemaze-software/',
  },
];
