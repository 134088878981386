import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { contentPropTypes } from '../../utils/prop_shapes';

const TextWrapper = ({ text, largeTitle, largeWrapper }) => {
  const textWrapper = classNames('text-wrapper', {
    large: largeWrapper,
  });
  const titlesClass = classNames({ large: largeTitle });
  const paragraphColor = classNames({ gray: !text.subtitle });

  const renderContent = (content, key) => (
    <React.Fragment key={key}>
      {content.title && <h2 className={titlesClass}>{content.title}</h2>}
      {content.subtitle && (
        <h2 className={`gray ${titlesClass}`}>{content.subtitle}</h2>
      )}
      {typeof content.description === 'string' ? (
        <p className={paragraphColor}>{content.description}</p>
      ) : (
        <ul>
          {Object.values(content.description).map((paragraph, index) => (
            <li key={index} className={paragraphColor}>
              {paragraph}
            </li>
          ))}
        </ul>
      )}
    </React.Fragment>
  );

  return <div className={textWrapper}>{renderContent(text)}</div>;
};

export default TextWrapper;

TextWrapper.propTypes = {
  text: contentPropTypes,
  largeTitle: PropTypes.bool,
  largeWrapper: PropTypes.bool,
};
