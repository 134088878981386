import React, { Component } from 'react';
import Waypoint from 'react-waypoint';
import classNames from 'classnames';

import BinderLogo from '../BinderLogo';
import TextWrapper from '../TextWrapper';

import binderYellow from '../../assets/projects/binder/binder_pallete_yellow.jpg';
import binderLight from '../../assets/projects/binder/binder_pallete_light.jpg';
import binderGray from '../../assets/projects/binder/binder_pallete_gray.jpg';
import binderDarkGray from '../../assets/projects/binder/binder_pallete_darkgray.jpg';
import binderDark from '../../assets/projects/binder/binder_pallete_dark.jpg';

const COLORS = [
  {
    image_source: binderYellow,
    color_hex: 'FEE94E',
    color: 'yellow',
  },
  {
    image_source: binderLight,
    color_hex: 'FBFCFE',
    color: 'light gray',
  },
  {
    image_source: binderGray,
    color_hex: 'EBEFF2',
    color: 'gray',
  },
  {
    image_source: binderDarkGray,
    color_hex: '90A4AD',
    color: 'dark gray',
  },
  {
    image_source: binderDark,
    color_hex: '263237',
    color: 'dark',
  },
];

const WHY_BINDER = {
  title: 'Why Binder?',
  description:
    'Binder is a platform that empowers musicians with tools to work together. It brings artists from all around the world closer.',
};

const BRANDING = {
  title: 'It’s yellow, it’s golden.',
  description:
    'Binder Studio is a work tool mostly designed with shades of blue-ish grey and, to spice things a bit, a touch of mellow yellow.',
};

const DARK_TEXT = ['FBFCFE', 'EBEFF2'];

export default class WhyBinder extends Component {
  state = {
    gridAnimating: false,
    logoAnimating: false,
  };

  gridAnimation = () => this.setState({ gridAnimating: true });

  logoAnimation = () => this.setState({ logoAnimating: true });

  firstText = () => {
    const { logoAnimating } = this.state;

    return (
      <React.Fragment>
        <TextWrapper text={WHY_BINDER} />
        <Waypoint onEnter={this.logoAnimation} bottomOffset="57%">
          <div className="movie-background">
            <BinderLogo isAnimating={logoAnimating} />
          </div>
        </Waypoint>
        <div className="grid-section-text">
          <TextWrapper text={BRANDING} />
        </div>
      </React.Fragment>
    );
  };

  handleGridItems = (gridItem) => {
    const { gridAnimating } = this.state;
    const itemClass = classNames('color-item', {
      'loading-animation': gridAnimating,
    });

    const colorClass = classNames('', {
      dark: DARK_TEXT.includes(gridItem.color_hex),
      light: !DARK_TEXT.includes(gridItem.color_hex),
    });

    return (
      <div className={itemClass} key={gridItem.color_hex}>
        <span className={colorClass}>{gridItem.color_hex}</span>
        <img
          alt={`color ${gridItem.color} : ${gridItem.color_hex}`}
          className={colorClass}
          src={gridItem.image_source}
        />
      </div>
    );
  };

  goldenGrid = () => (
    <div className="wrapper">
      <Waypoint onEnter={this.gridAnimation} bottomOffset="57%">
        <div className="golden-grid">
          {COLORS.map((gridItem) => this.handleGridItems(gridItem))}
        </div>
      </Waypoint>
    </div>
  );

  render() {
    return (
      <section className="why-binder section-padding separator">
        {this.firstText()}
        {this.goldenGrid()}
      </section>
    );
  }
}
