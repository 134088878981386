import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export default class TextArea extends Component {
  state = {
    active: false,
  };

  handleChange = (event) => {
    const element = event.target;
    const hasContent = element.value.length > 0;

    this.setState({ active: hasContent });

    let newHeight;
    if (hasContent) {
      element.style.height = '1px';
      newHeight = `${2 + element.scrollHeight}px`;
    } else {
      newHeight = 'auto';
    }
    element.style.height = newHeight;
  };

  render() {
    const { id, label, error, ...inputProps } = this.props;
    const { active } = this.state;
    const labelClasses = classNames({ active, error });

    return (
      <React.Fragment>
        <label htmlFor={id} className={labelClasses}>
          <span>{label}</span>
          <textarea
            id={id}
            rows={1}
            onChange={this.handleChange}
            {...inputProps}
          />
        </label>
        {error && <span className="error">{error}</span>}
      </React.Fragment>
    );
  }
}

TextArea.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
};
