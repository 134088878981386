import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { deemazerShape } from '../../utils/prop_shapes';
import SocialNetworks from '../SocialNetworks';

const FOUNDERS = ['João Monteiro', 'Paulo Santos', 'António Marques'];

const renderDeemazerInfo = (deemazer) => (
  <div className="info">
    <p>{deemazer.name}</p>
    <p>{deemazer.role}</p>
    <div className="links-wrapper">
      <SocialNetworks networks={deemazer.social_links} />
    </div>
  </div>
);

const Deemazer = ({ deemazer, factValue, animationProgress }) => {
  let imageStyle = {};
  let wrapperStyle = {};
  const deemazerWrapperClassName = classNames('deemazer-wrapper', {
    founder: FOUNDERS.includes(deemazer.name),
  });

  if (!FOUNDERS.includes(deemazer.name)) {
    const scale = 0.8 + (0.2 * animationProgress) / 100;
    const translate = 25 - 0.25 * animationProgress;
    const display = animationProgress > 0 ? 'block' : 'none';
    wrapperStyle = {
      transform: `scale(${scale}) translateY(${translate}px)`,
      opacity: animationProgress / 100,
      display,
    };
  }

  imageStyle = {
    filter: `brightness(${factValue}%)`,
  };

  return (
    <div
      className={deemazerWrapperClassName}
      key={deemazer.id}
      id={deemazer.id}
      style={wrapperStyle}
    >
      {renderDeemazerInfo(deemazer, animationProgress)}
      <img
        src={deemazer.asset.file_path}
        alt={deemazer.name}
        id={deemazer.id}
        style={imageStyle}
      />
    </div>
  );
};

export default Deemazer;

Deemazer.propTypes = {
  deemazer: deemazerShape.isRequired,
  factValue: PropTypes.number.isRequired,
  animationProgress: PropTypes.number.isRequired,
};
