import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
// eslint-disable-next-line no-unused-vars
import whatInput from 'what-input';
import { Router } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { createBrowserHistory } from 'history';
import Honeybadger from '@honeybadger-io/js';

import { HoneybadgerErrorBoundary } from '@honeybadger-io/react';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Main from '../../components/Main';
import { ProjectHeaderRefProvider } from '../../components/ProjectHeaderRefContext';
import customFetch from '../../utils/network_utils';
import ProjectPageFooter from '../../components/ProjectPageFooter';

import './Application.scss';

const history = createBrowserHistory();
ReactGA.initialize('G-H9BHD90F0E');
history.listen((location) => {
  ReactGA.send({
    hitType: 'pageview',
    page: location.pathname + location.search,
    testMode: process.env.NODE_ENV === 'development',
  });
});

library.add(fab);
const projectHeaderRef = React.createRef();
const SCROLL_OFFSET = 150;

const Application = ({ railsContext }) => {
  const honeybadger = Honeybadger.configure(railsContext.honeybadgerConfig);

  const [projects, setProjects] = useState([]);
  const [selectedType, setSelectedType] = useState('everything');
  const [projectPage, setProjectPage] = useState(false);
  const [hasScrolledProjectHeader, setHasScrolledProjectHeader] =
    useState(false);
  const [currentPath, setCurrentPath] = useState('');

  // Randomly pick 4 projects to display in the footer
  const footerProjects = useMemo(() => {
    const currentProject = currentPath.replace('/projects/', '');

    // Exclude the current project
    const selectedProjects = projects.filter(
      (project) => project.name_slug !== currentProject,
    );

    // Randomize the projects
    for (let i = selectedProjects.length - 1; i > 0; i -= 1) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = selectedProjects[i];
      selectedProjects[i] = selectedProjects[j];
      selectedProjects[j] = temp;
    }

    // Return the first 4
    return selectedProjects.slice(0, 4);
  }, [projects, currentPath]);

  const handleProjectHeaderStateChange = useCallback(() => {
    const scrollAmount = window.scrollY;
    const node = projectHeaderRef.current;
    if (projectPage && scrollAmount - SCROLL_OFFSET >= node.offsetHeight) {
      setHasScrolledProjectHeader(true);
    } else {
      setHasScrolledProjectHeader(false);
    }
  }, [projectPage]);

  const handleCurrentPathChange = (newPath) => {
    setCurrentPath(newPath);
  };

  useEffect(() => {
    const getProjects = async () => {
      const response = await customFetch('/api/projects');
      setProjects(response);
    };

    getProjects();
  }, []);

  // Observe the page scroll to keep the project header state updated
  useEffect(() => {
    window.addEventListener('scroll', handleProjectHeaderStateChange);

    return () =>
      window.removeEventListener('scroll', handleProjectHeaderStateChange);
  }, [handleProjectHeaderStateChange]);

  return (
    <HoneybadgerErrorBoundary honeybadger={honeybadger}>
      <Router history={history}>
        <div className="app-container">
          <Header
            projects={projects}
            selectProjectType={setSelectedType}
            setProjectPage={setProjectPage}
            projectPage={projectPage}
            hasScrolledProjectHeader={hasScrolledProjectHeader}
            currentPath={currentPath}
            setCurrentPath={handleCurrentPathChange}
          />
          <ProjectHeaderRefProvider value={projectHeaderRef}>
            <Main projects={projects} selectedType={selectedType} />
          </ProjectHeaderRefProvider>
          {projectPage && <ProjectPageFooter projects={footerProjects} />}
          <Footer />
        </div>
      </Router>
    </HoneybadgerErrorBoundary>
  );
};

Application.propTypes = {
  railsContext: PropTypes.shape({
    honeybadgerConfig: PropTypes.shape({
      apiKey: PropTypes.string,
      environment: PropTypes.string,
      projectRoot: PropTypes.string,
      revision: PropTypes.string,
    }),
  }),
};

export default (_props, railsContext) => () => (
  <Application railsContext={railsContext} />
);
