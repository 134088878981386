import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export default class MenuList extends Component {
  state = {};

  render() {
    const { projects, setProjectsHeaderOptions } = this.props;

    return (
      <React.Fragment>
        <ul className="menu-list">
          {projects.map((project, index) => (
            <li key={index}>
              <Link
                to={`/projects/${project.name_slug}`}
                className="project-link"
                onClick={() => setProjectsHeaderOptions(project.name_slug)}
              >
                {project.name}
              </Link>
            </li>
          ))}
        </ul>
      </React.Fragment>
    );
  }
}

MenuList.propTypes = {
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ),
  setProjectsHeaderOptions: PropTypes.func.isRequired,
};
