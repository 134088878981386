import React from 'react';
import PropTypes from 'prop-types';

import { imageShape } from '../../utils/prop_shapes';

const CareerIntro = ({ content }) => {
  const {
    image: { source, alt },
  } = content;
  return (
    <section className="section-padding career-intro">
      <div className="intro-text-wrapper">
        <h2 className="title">{content.title}</h2>
        <h2 className="subtitle">{content.subtitle}</h2>
        <p className="description">{content.description}</p>
        <a className="cta" href="#contact-us">
          Apply
        </a>
      </div>
      <img className="image" src={source} alt={alt} />
    </section>
  );
};

CareerIntro.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    description: PropTypes.string,
    image: imageShape,
  }),
};

export default CareerIntro;
