import React from 'react';
import PropTypes from 'prop-types';

import ProjectHeader from '../ProjectHeader';
import ProjectInfo from '../ProjectInfo';
import DesktopShowcase from '../DesktopShowcase';
import FullWidthMedia from '../FullWidthMedia';
import { projectsShape } from '../../utils/prop_shapes';
import ProductIntroduction from '../ProductIntroduction';
import DeviceLabBranding from '../DeviceLabBranding';
import DeviceLabResponsive from '../DeviceLabResponsive';
import DeviceLabPhotos from '../DeviceLabPhotos';
import SiteMetadata from '../SiteMetadata';

import landingPage from '../../assets/projects/lab/landing_page.jpg';
import contribute from '../../assets/projects/lab/contribute.svg';
import contributeMobile from '../../assets/projects/lab/contribute_mobile.svg';
import newSession from '../../assets/projects/lab/new_session.svg';
import iconLight from '../../assets/projects/lab/icon_light.svg';
import iconDark from '../../assets/projects/lab/icon_dark.svg';
import typeDesktop from '../../assets/projects/lab/type_desktop.svg';
import typeTablet from '../../assets/projects/lab/type_tablet.svg';
import typeMobile from '../../assets/projects/lab/type_mobile.svg';
import responsive1 from '../../assets/projects/lab/responsive_1.jpg';
import responsive2 from '../../assets/projects/lab/responsive_2.jpg';
import responsive3 from '../../assets/projects/lab/responsive_3.jpg';
import device1 from '../../assets/projects/lab/IMG_1267.jpg';
import device2 from '../../assets/projects/lab/IMG_1314.jpg';
import device3 from '../../assets/projects/lab/IMG_1255.jpg';
import device4 from '../../assets/projects/lab/IMG_1261.jpg';
import device5 from '../../assets/projects/lab/IMG_1280.jpg';
import device6 from '../../assets/projects/lab/IMG_1276.jpg';
import labCover from '../../assets/projects/lab/lab_cover.svg';

// meta data props
const baseMetadata = {
  description:
    'Space that provides internet-capable devices to test software across a variety of screens and hardware.',
  siteUrl: 'https://deemaze.com/projects/devicelab',
  sitePrefix: 'DeviceLab',
};

const LANDING_PAGE_SECTION = {
  title: 'We know you have questions.',
  description:
    'For someone who’s not familiar with the concept of a Device Lab, it might be hard to see its value right away. With that in mind, the first step was to launch a landing page explaining its purpose and how it all works.',
  image: {
    alt: 'Landing page layout: several Q&A sections explaining how device labs work.',
    source: landingPage,
  },
};

const CONTRIBUTE = {
  type: 'image',
  sources: {
    desktop: contribute,
    mobile: contributeMobile,
  },
  alt: 'Contribute form containing fields for name, device type and email.',
};

const NEW_SESSION = {
  title: 'Let’s start testing!',
  description:
    'Once the lab was ready, we launched the platform for testers to schedule their testing sessions.',
  image: {
    alt: 'Screen to schedule a new session, containing a list of devices and date-time picker.',
    source: newSession,
  },
};

const DEVICELAB_INTRODUCTION = [
  {
    title: 'The reality',
    description:
      'At Deemaze, we develop software for several platforms and operating systems. Since different projects have to be tested across different devices, that often led us to acquire new devices or even using our own.',
  },
  {
    title: 'Problem',
    description:
      'What about small studios or freelance developers? Buying new devices to test each project might pose an unsustainable expense when you’re on a tight budget.',
  },
  {
    title: 'Solution',
    description:
      'We decided to build the first Open Device Lab in Portugal! Both the tech community and the projects benefit - everyone is happy.',
  },
  {
    title: 'How to help',
    description:
      'We are always collecting devices: smartphones, tablets, game consoles,… send them our way. We’ll take good care of them. Oh, and spread the word! What’s a testing lab without testers?',
  },
];

const DEVICELAB_BRANDING = {
  title: 'Explore, build, fix, ship.',
  description:
    'Developing software is exciting! Is like playing with new legos on Christmas morning. We wanted the lab’s branding to be friendly, a bit playful, and a LOT about exploring and building the unknown.',
  logo: [iconLight, iconDark],
  typography: [typeDesktop, typeTablet, typeMobile],
};

const DEVICELAB_PALETTE = [
  {
    name: 'accentblue',
    hex_code: '#16ccff',
  },
  {
    name: 'red',
    hex_code: '#f9536c',
  },
  {
    name: 'violet',
    hex_code: '#d9d7ff',
  },
  {
    name: 'dark',
    hex_code: '#04003d',
  },
  {
    name: 'blue',
    hex_code: '#07006f',
  },
  {
    name: 'white',
    hex_code: '#ffffff',
  },
];

const DEVICELAB_RESPONSIVE = {
  title: 'Access it anywhere.',
  description:
    'Schedule (or even unschedule) your sessions from your computer or your phone, whatever suits you better.',
};

const DEVICELAB_LAYOUTS = [
  {
    alt: 'Mobile layout - New session.',
    source: responsive1,
  },
  {
    alt: 'Desktop layout - Profile.',
    source: responsive2,
  },
  {
    alt: 'Tablet layout - New session.',
    source: responsive3,
  },
];

const DEVICELAB_PHOTOS_COPY = {
  title: 'Breaking news: we’re live.',
  description:
    'The first Open Device Lab in Portugal is up and running. So make sure to pay us a visit when you have products to test!',
};

const DEVICELAB_PHOTOS_IMAGES = [
  {
    alt: 'Motorola G, mobile device.',
    source: device1,
  },
  {
    alt: 'Mobile devices displayed on a table.',
    source: device2,
  },
  {
    alt: 'Acer, mobile device.',
    source: device3,
  },
  {
    alt: 'Motorola E, mobile device.',
    source: device4,
  },
  {
    alt: 'uGrokit, RFID reader for smartphone.',
    source: device5,
  },
  {
    alt: 'Leap motion device.',
    source: device6,
  },
];

const DeviceLab = ({ project }) => (
  <main className="devicelab-wrapper full-width">
    <SiteMetadata
      {...baseMetadata}
      keywords={project.keywords}
      social={project.assets.social ? project.assets.social : ''}
    />
    <ProjectHeader project={project} coverImageUrl={labCover} whiteText />
    <ProductIntroduction
      productName={project.name}
      productIntroduction={DEVICELAB_INTRODUCTION}
    />
    <DeviceLabBranding
      brandingContent={DEVICELAB_BRANDING}
      palette={DEVICELAB_PALETTE}
    />
    <DesktopShowcase desktopSection={LANDING_PAGE_SECTION} />
    <FullWidthMedia media={CONTRIBUTE} />
    <DesktopShowcase desktopSection={NEW_SESSION} />
    <DeviceLabResponsive
      copy={DEVICELAB_RESPONSIVE}
      layouts={DEVICELAB_LAYOUTS}
    />
    <DeviceLabPhotos
      copy={DEVICELAB_PHOTOS_COPY}
      photos={DEVICELAB_PHOTOS_IMAGES}
    />
    <ProjectInfo
      project={project}
      madeWith={project.made_with}
      listOfTasks={project.our_role}
      linkToProject={project.link}
    />
  </main>
);

export default DeviceLab;

DeviceLab.propTypes = {
  project: PropTypes.shape(projectsShape),
};
