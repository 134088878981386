import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ProjectHeader from '../ProjectHeader';
import ProjectInfo from '../ProjectInfo';
import customFetch from '../../utils/network_utils';
import { projectsShape } from '../../utils/prop_shapes';
import PopDescription from '../PopDescription';
import PopArtists from '../PopArtists';
import PopStatistics from '../PopStatistics';
import SiteMetadata from '../SiteMetadata';

import popBroadcast from '../../assets/projects/pop/pop_broadcast.svg';
import popBroadcastMobile from '../../assets/projects/pop/pop_broadcast_mobile.svg';
import popTarget from '../../assets/projects/pop/pop_target.svg';
import popTargetMobile from '../../assets/projects/pop/pop_target_mobile.svg';
import popSchedule from '../../assets/projects/pop/pop_schedule.svg';
import popScheduleMobile from '../../assets/projects/pop/pop_schedule_mobile.svg';
import popStatistics from '../../assets/projects/pop/pop_statistics.svg';
import popIcon1 from '../../assets/projects/pop/pop_icon_1.svg';
import popIcon2 from '../../assets/projects/pop/pop_icon_2.svg';
import popIcon3 from '../../assets/projects/pop/pop_icon_3.svg';
import popIcon4 from '../../assets/projects/pop/pop_icon_4.svg';
import popCover from '../../assets/projects/pop/pop_cover.svg';

// meta data props
const baseMetadata = {
  description:
    'Broadcast interactive messages to your audience directly on Facebook Messenger.',
  siteUrl: 'https://deemaze.com/projects/iampop',
  sitePrefix: 'I AM POP',
};

// Props to pass on to description section
const POP_INTRODUCTION = [
  {
    title: 'Broadcast',
    description:
      'Send important announcements or interactive conversations: with POP, your messages will have open rates above 94%.',
    image: {
      alt: 'Page with message editor and broadcast history.',
      source: popBroadcast,
    },
    image_mobile: popBroadcastMobile,
  },
  {
    title: 'Target',
    description:
      'You can target your message according to your audience’s timezone, interests, language, etc. Whatever suits you!',
    image: {
      alt: 'Popup layout to target messages for specific fan groups.',
      source: popTarget,
    },
    image_mobile: popTargetMobile,
  },
  {
    title: 'Schedule',
    description:
      'Prepare content in advance! You can schedule unlimited messages, to be sent on your audience’s timezone or your own.',
    image: {
      alt: 'Popup layout to schedule messages.',
      source: popSchedule,
    },
    image_mobile: popScheduleMobile,
  },
];

// Props to pass on to tracking section
const TRACKING_INTRODUCTION = {
  title: 'Track your reach',
  description:
    'POP provides statistics about your audience and broadcasts. You’ll be amazed to see your fanbase quickly grow while keeping amazing open rates!',
};

const TRACKING_STATISTICS_PHOTO = {
  source: popStatistics,
  alt: 'Pop statistics chart.',
};

const TRACKING_FEATURES = [
  {
    description: 'Track audience growth, over time and after each message.',
    icon: popIcon1,
  },
  {
    description: 'Discover the top languages spoken by your subscribers.',
    icon: popIcon2,
  },
  {
    description: 'Check how many fans you have in each channel or segment.',
    icon: popIcon3,
  },
  {
    description: 'Track open rates of each message.',
    icon: popIcon4,
  },
];

// Props to pass on to artists component
const ARTISTS_GALLERY_TEXT = {
  title: 'See POP in action',
  description:
    'POP is being used by several artists, bands, festivals, record labels — you name it! Go ahead and talk to them.',
};

export default class Pop extends Component {
  state = {
    popArtists: [],
  };

  componentDidMount() {
    this.loadPopArtists();
  }

  async loadPopArtists() {
    const popArtists = await customFetch('/api/pop_artists');
    this.setState({ popArtists });
  }

  render() {
    const { project } = this.props;
    const { popArtists } = this.state;
    return (
      <main className="iampop-wrapper full-width">
        <SiteMetadata
          {...baseMetadata}
          keywords={project.keywords}
          social={project.assets.social ? project.assets.social : ''}
        />
        <ProjectHeader project={project} coverImageUrl={popCover} whiteText />
        <PopDescription popIntroduction={POP_INTRODUCTION} />
        <PopStatistics
          trackingIntroduction={TRACKING_INTRODUCTION}
          trackingFeatures={TRACKING_FEATURES}
          trackingPhoto={TRACKING_STATISTICS_PHOTO}
        />
        <PopArtists
          artistsGalleryText={ARTISTS_GALLERY_TEXT}
          artistsInformation={popArtists}
        />
        <ProjectInfo
          project={project}
          madeWith={project.made_with}
          listOfTasks={project.our_role}
          linkToProject={project.link}
        />
      </main>
    );
  }
}

Pop.propTypes = {
  project: PropTypes.shape(projectsShape),
};
