import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import LinkCard from '../LinkCard';
import TextWrapper from '../TextWrapper';

const CallToAction = ({ text, types, background }) => {
  const separatorClass = classNames('call-to-action full-width', {
    'bg-light': background === 'light',
    'bg-white': background === 'white',
  });

  return (
    <React.Fragment>
      <section className={separatorClass}>
        <div className="grid-wrapper section-padding">
          <TextWrapper text={text} largeTitle largeWrapper />
          {types.map((type) => (
            <LinkCard type={type} key={type} />
          ))}
        </div>
      </section>
    </React.Fragment>
  );
};

export default CallToAction;
CallToAction.propTypes = {
  text: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    description: PropTypes.string.isRequired,
  }),
  background: PropTypes.string.isRequired,
  types: PropTypes.arrayOf(PropTypes.string).isRequired,
};
