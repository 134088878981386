import React, { Component } from 'react';
import Slider from 'react-slick';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import Waypoint from 'react-waypoint';

import Arrow from '../Arrow';

export default class PhotoGallery extends Component {
  state = {
    activePhoto: 0,
  };

  setActivePhoto = (_oldIndex, newIndex) => {
    this.setState({ activePhoto: newIndex });
  };

  renderPhotos = (photoObject, index, size) => {
    const { activePhoto } = this.state;
    const photoWrapperClass = classNames('photo-label-wrapper', {
      'hide-label': activePhoto !== index,
    });
    const { galleryNumber } = this.props;

    return (
      <React.Fragment>
        {index === 1 && (galleryNumber === 2 || galleryNumber === 3) ? (
          <Waypoint
            onEnter={this.addVisibility}
            onLeave={this.removeVisibility}
            topOffset="60%"
          >
            <img
              src={photoObject.asset.file_path}
              alt={photoObject.photo_label}
            />
          </Waypoint>
        ) : (
          <img
            src={photoObject.asset.file_path}
            alt={photoObject.photo_label}
          />
        )}
        <div className={photoWrapperClass}>
          <span />
          <div className="flex-wrapper">
            <p className="photo-index">{`${index + 1}/${size}`}</p>
            <p className="photo-label">{photoObject.photo_label}</p>
          </div>
        </div>
      </React.Fragment>
    );
  };

  addVisibility = () => {
    const {
      setSecondGalleryVisible,
      setThirdGalleryVisible,
      galleryNumber,
      setisSecondGaleryAbove,
      setFadeOut,
    } = this.props;

    if (galleryNumber === 2) {
      setSecondGalleryVisible(true);
      setisSecondGaleryAbove(false);
      setFadeOut(2, false);
    } else if (galleryNumber === 3) {
      setThirdGalleryVisible(true);
    }
  };

  removeVisibility = (props) => {
    const {
      setSecondGalleryVisible,
      setThirdGalleryVisible,
      galleryNumber,
      setisSecondGaleryAbove,
      setFadeOut,
    } = this.props;

    if (galleryNumber === 2) {
      if (props.currentPosition !== Waypoint.above) {
        setSecondGalleryVisible(false);
        setisSecondGaleryAbove(false);
        setFadeOut(2, true);
      }
    } else if (galleryNumber === 3) {
      if (props.currentPosition === Waypoint.below) {
        setisSecondGaleryAbove(true);
      }
      setThirdGalleryVisible(false);
      setFadeOut(3, true);
      if (props.currentPosition === Waypoint.above) {
        setThirdGalleryVisible(true);
      }
    }
  };

  render() {
    const { gallery, galleryText, galleryNumber } = this.props;
    const settings = {
      arrows: true,
      infinite: false,
      speed: 500,
      variableWidth: true,
      slidesToShow: 1,
      dots: true,
      prevArrow: <Arrow direction="left" />,
      nextArrow: <Arrow direction="right" />,
      responsive: [
        {
          breakpoint: 750,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
      beforeChange: this.setActivePhoto,
    };

    const photoGalleryWrapperClass = classNames('photo-gallery full-width', {
      'add-margin': galleryNumber === 1,
      'remove-top': galleryNumber === 1,
    });

    return (
      <React.Fragment>
        {galleryText && (
          <div className="galleries-text desktop-hide">
            <div className="text-wrapper">
              <p>{galleryText}</p>
            </div>
          </div>
        )}
        <div className={photoGalleryWrapperClass}>
          {gallery && (
            <Slider {...settings} className="full-width">
              {gallery
                .slice(0)
                .reverse()
                .map((photoObject, index) => (
                  <div className="photo-wrapper" key={`photo-${index}`}>
                    {this.renderPhotos(photoObject, index, gallery.length)}
                  </div>
                ))}
            </Slider>
          )}
        </div>
      </React.Fragment>
    );
  }
}

PhotoGallery.propTypes = {
  galleryNumber: PropTypes.number.isRequired,
  galleryText: PropTypes.string,
  setSecondGalleryVisible: PropTypes.func,
  setThirdGalleryVisible: PropTypes.func,
  setisSecondGaleryAbove: PropTypes.func,
  gallery: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      picture: PropTypes.string,
    }),
  ),
  setFadeOut: PropTypes.func,
};
