import React from 'react';
import PropTypes from 'prop-types';

const FullWidthMedia = ({ media }) => (
  <div className="full-width-media full-width">
    <img
      className="full-width desktop-version"
      src={media.sources.desktop}
      alt={media.alt}
    />
    <img
      className="full-width mobile-version"
      src={media.sources.mobile}
      alt={media.alt}
    />
  </div>
);

export default FullWidthMedia;

FullWidthMedia.propTypes = {
  media: PropTypes.shape({
    type: PropTypes.string.isRequired,
    sources: PropTypes.shape({
      desktop: PropTypes.string.isRequired,
      mobile: PropTypes.string,
    }),
    alt: PropTypes.string,
  }),
};
