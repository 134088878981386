import React from 'react';
import PropTypes from 'prop-types';

import { projectsShape } from '../../utils/prop_shapes';

const renderLists = (name, list, type) => (
  <React.Fragment>
    <p className={name}>{type}</p>
    <ul>
      {list.map((item) => (
        <li key={item}>{item}</li>
      ))}
    </ul>
  </React.Fragment>
);

const ProjectInfo = (props) => {
  const { project, madeWith, listOfTasks, linkToProject } = props;

  return (
    <div className="project-info-wrapper">
      <div className="info-grid">
        {madeWith &&
          madeWith.length > 0 &&
          renderLists(project.name_slug, madeWith, 'Made with:')}
        {listOfTasks &&
          renderLists(project.name_slug, listOfTasks, 'What we did:')}
        {linkToProject && (
          <a
            href={`http://www.${linkToProject}`}
            className={`project-link ${project.name_slug}`}
            target="_blank"
            rel="noreferrer"
          >
            {linkToProject}
          </a>
        )}
      </div>
    </div>
  );
};

export default ProjectInfo;

ProjectInfo.propTypes = {
  project: PropTypes.shape(projectsShape),
  madeWith: PropTypes.arrayOf(PropTypes.string),
  listOfTasks: PropTypes.arrayOf(PropTypes.string),
  linkToProject: PropTypes.string,
};
