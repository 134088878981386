import React from 'react';
import PropTypes from 'prop-types';

const ProductIntroduction = ({ productName, productIntroduction }) => (
  <React.Fragment>
    <section className="product-introduction section-padding separator">
      <h2>{`About ${productName}:`}</h2>
      {productIntroduction.map((introduction, index) => (
        <div className="description-wrapper" key={`intro-${index}`}>
          <h3>{introduction.title}</h3>
          <p className="gray">{introduction.description}</p>
        </div>
      ))}
    </section>
  </React.Fragment>
);

export default ProductIntroduction;

ProductIntroduction.propTypes = {
  productName: PropTypes.string.isRequired,
  productIntroduction: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }),
  ),
};
