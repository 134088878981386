import React from 'react';
import PropTypes from 'prop-types';

import { deemazerShape, listOfFactsShape } from '../../utils/prop_shapes';
import Deemazer from '../Deemazer';

const getFactValue = (deemazer, listOfFacts, randomFactId) => {
  let percentageValue = 100;
  if (randomFactId !== -1) {
    percentageValue = listOfFacts[randomFactId].deemazer_percentages.filter(
      (fact) => deemazer.id === fact.deemazer_id,
    )[0].fact_percentage;
  }
  return percentageValue;
};

const Gallery = ({
  deemazers,
  animationProgress,
  listOfFacts,
  randomFactId,
}) => (
  <div name="Who" className="gallery-wrapper">
    <div className="team-grid">
      {deemazers.map((deemazer) => (
        <Deemazer
          key={deemazer.id}
          deemazer={deemazer}
          factValue={getFactValue(deemazer, listOfFacts, randomFactId)}
          animationProgress={animationProgress}
        />
      ))}
    </div>
  </div>
);

export default Gallery;

Gallery.propTypes = {
  deemazers: PropTypes.arrayOf(deemazerShape),
  listOfFacts: PropTypes.arrayOf(listOfFactsShape),
  randomFactId: PropTypes.number.isRequired,
  animationProgress: PropTypes.number.isRequired,
};
