import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const icons = {
  linkedin: 'linkedin-in',
  github: 'github',
  dribbble: 'dribbble',
  twitter: 'twitter',
  medium: 'medium-m',
  instagram: 'instagram',
  facebook: 'facebook-f',
};

const SocialNetworks = ({ networks }) => (
  <React.Fragment>
    {networks.map((network) => (
      <a
        href={network.link_url}
        target="_blank"
        className="network-link"
        aria-label={network.link_type}
        key={network.link_type}
        rel="noreferrer"
      >
        <FontAwesomeIcon
          icon={['fab', icons[network.link_type]]}
          className="network-icon"
          size="1x"
          title={network.link_type}
        />
      </a>
    ))}
  </React.Fragment>
);

export default SocialNetworks;

SocialNetworks.propTypes = {
  networks: PropTypes.arrayOf(
    PropTypes.shape({
      link_type: PropTypes.string.isRequired,
      link_url: PropTypes.string.isRequired,
    }),
  ),
};
