import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

const title = 'Deemaze';

const SiteMetadata = ({
  keywords,
  description,
  social,
  siteUrl,
  sitePrefix,
  isHome,
}) => (
  <Helmet
    defaultTitle="Deemaze"
    titleTemplate={isHome ? 'Deemaze Software' : '%s | Deemaze'}
  >
    <title>{sitePrefix}</title>
    <meta name="keywords" content={keywords} />
    <meta name="description" content={description} />
    <meta property="og:url" content={siteUrl} />
    <meta property="og:site_name" content={title} />
    <meta property="og:image" content={social} />
    <link rel="canonical" href={siteUrl} />
    {isHome ? <meta name="theme-color" content="#C8102E" /> : null}
  </Helmet>
);

export default SiteMetadata;
SiteMetadata.propTypes = {
  keywords: PropTypes.string,
  description: PropTypes.string,
  social: PropTypes.string,
  siteUrl: PropTypes.string,
  sitePrefix: PropTypes.string,
  isHome: PropTypes.bool,
};
