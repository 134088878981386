import React from 'react';
import PropTypes from 'prop-types';

import { imageShape } from '../../utils/prop_shapes';
import TextWrapper from '../TextWrapper';

const coreFeatures = (trackingFeatures) => (
  <div className="audience-info">
    {trackingFeatures.map((feature, index) => (
      <div className="info" key={index}>
        <img src={feature.icon} alt="" />
        <p>{feature.description}</p>
      </div>
    ))}
  </div>
);

const renderMobileLayout = (
  trackingIntroduction,
  trackingPhoto,
  trackingFeatures,
) => (
  <div className="mobile-wrapper full-width-mobile">
    <TextWrapper text={trackingIntroduction} />
    <div className="overflow-wrapper full-width-mobile">
      <div className="image-wrapper">
        <img src={trackingPhoto.source} alt={trackingPhoto.alt} />
      </div>
    </div>
    {coreFeatures(trackingFeatures)}
  </div>
);

const renderDesktopLayout = (
  trackingIntroduction,
  trackingPhoto,
  trackingFeatures,
) => (
  <div className="desktop-wrapper">
    <div>
      <TextWrapper text={trackingIntroduction} />
      {coreFeatures(trackingFeatures)}
    </div>
    <div className="image-wrapper">
      <img src={trackingPhoto.source} alt={trackingPhoto.alt} />
    </div>
  </div>
);

const PopStatistics = ({
  trackingIntroduction,
  trackingFeatures,
  trackingPhoto,
}) => (
  <section className="pop-statistics section-padding full-width bg-light">
    {renderMobileLayout(trackingIntroduction, trackingPhoto, trackingFeatures)}
    {renderDesktopLayout(trackingIntroduction, trackingPhoto, trackingFeatures)}
  </section>
);

export default PopStatistics;

PopStatistics.propTypes = {
  trackingIntroduction: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }),
  trackingFeatures: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
    }),
  ),
  trackingPhoto: imageShape.isRequired,
};
