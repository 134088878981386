import React, { Component } from 'react';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
import Masonry from 'react-masonry-component';

import ProjectPreview from '../ProjectPreview';
import { projectsShape } from '../../utils/prop_shapes';
import SiteMetadata from '../SiteMetadata';

import ogPreview from '../../assets/og_preview.png';

const masonryOptions = {
  transitionDuration: 1,
};

// meta data props
const metadata = {
  keywords: [
    'Deemaze',
    'Deemaze Software',
    'Digital Product Development',
    'Digital Services Devlopment',
    'App Development',
    'Product Development',
    'Software Devolpment',
    'Apps',
    'Mobile App',
    'Mobile App Development',
    'Web',
    'Web Design',
    'Software',
    'iOS',
    'Android',
    'Website',
    'build an MVP',
    'Projects',
  ],
  description:
    'This is Deemaze, take a look at some of our Software Development work.',
  siteUrl: 'https://deemaze.com/projects',
  sitePrefix: 'Projects',
  social: ogPreview,
};

export default class ProjectPreviewWrapper extends Component {
  filter = memoize((projects, selectedType, changed) => {
    if (changed) {
      this.setState({ changed: false });
      return [];
    }

    if (selectedType === 'everything') {
      return projects;
    }
    return projects.filter((project) =>
      project.project_type.includes(selectedType),
    );
  });

  constructor(props) {
    super(props);

    const { selectedType } = this.props;

    this.state = {
      currSelectedType: selectedType,
      changed: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.currSelectedType !== nextProps.selectedType) {
      return { currSelectedType: nextProps.selectedType, changed: true };
    }

    return prevState;
  }

  render() {
    const { projects, selectedType } = this.props;
    const { changed } = this.state;
    const filteredProjects = this.filter(projects, selectedType, changed);

    return (
      <main>
        <SiteMetadata {...metadata} />
        {filteredProjects.length > 0 && (
          <Masonry className="project-preview-wrapper" options={masonryOptions}>
            {filteredProjects.map((project) => (
              <ProjectPreview key={project.id} project={project} />
            ))}
          </Masonry>
        )}
      </main>
    );
  }
}

ProjectPreviewWrapper.propTypes = {
  projects: PropTypes.arrayOf(projectsShape),
  selectedType: PropTypes.string.isRequired,
};
