import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const renderProjects = (projects) => (
  <div className="projects-wrapper">
    {projects.map((project) => (
      <Link to={`/projects/${project.name_slug}`} key={project.id}>
        <div className="image-wrapper">
          <img src={project.assets.footer} alt={project.name} />
        </div>
      </Link>
    ))}
  </div>
);

const ProjectPageFooter = ({ projects }) => (
  <section className="project-page-footer separator">
    <h2 className="gray">Ready to talk?</h2>
    <Link to="/contacts">Contacts</Link>
    <p className="gray">Or, you know, keep exploring.</p>
    {renderProjects(projects)}
  </section>
);

export default ProjectPageFooter;

ProjectPageFooter.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.object),
};
