import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Waypoint from 'react-waypoint';
import classNames from 'classnames';

export default class ServicesList extends Component {
  state = {
    startAnimating: false,
  };

  startAnimation = () => this.setState({ startAnimating: true });

  render() {
    const { servicesList } = this.props;
    const { startAnimating } = this.state;
    const serviceClass = classNames('service', {
      'loading-animation': startAnimating,
    });

    return (
      <section className="services-list section-padding">
        {servicesList.map((service, index) => (
          <Waypoint
            onEnter={this.startAnimation}
            bottomOffset="30%"
            key={`service-${index}`}
          >
            <div className={serviceClass}>
              <h3>{service.service}</h3>
              <p>{service.description}</p>
              <ul>
                {service.subservices.map((subservice, keyIndex) => (
                  <li className="gray" key={keyIndex}>
                    {subservice}
                  </li>
                ))}
              </ul>
            </div>
          </Waypoint>
        ))}
      </section>
    );
  }
}

ServicesList.propTypes = {
  servicesList: PropTypes.arrayOf(
    PropTypes.shape({
      service: PropTypes.string,
      description: PropTypes.string,
      examples: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
};
