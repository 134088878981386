import React from 'react';

import ContactForm from '../ContactForm';
import FindUs from '../FindUs';
import SiteMetadata from '../SiteMetadata';

import ogPreview from '../../assets/og_preview.png';
import nest from '../../assets/nest.jpg';

// meta data props
const metadata = {
  keywords: [
    'Deemaze',
    'Deemaze Software',
    'Digital Product Development',
    'Digital Services Devlopment',
    'App Development',
    'Product Development',
    'Software Devolpment',
    'Apps',
    'Mobile App',
    'Mobile App Development',
    'Web',
    'Web Design',
    'Software',
    'iOS',
    'Android',
    'Website',
    'build an MVP',
    'Contact',
  ],
  description: "This is Deemaze, let's talk!",
  siteUrl: 'https://deemaze.com/contacts',
  sitePrefix: 'Contacts',
  social: ogPreview,
};

const TITLE = ['Planning a visit,', 'Or want to send us gifts?'];

const ADDRESSES = {
  coimbra: {
    street: 'Rua da Sota, 2A, 3º Andar',
    postcode: '3000-392 Coimbra',
    country: 'Portugal',
  },
  braga: {
    street: 'Rua D. Pedro V, 224, 1º Dto Trás',
    postcode: '4710-374 Braga',
    country: 'Portugal',
  },
  martingny: {
    street: 'Rue du Simplon 14',
    postcode: '1920 Martigny',
    country: 'Switzerland',
  },
};

const IMAGE = {
  alt: 'Nest Collective lobby',
  source: nest,
};

const ContactUs = () => (
  <main className="contact-us full-width">
    <SiteMetadata {...metadata} />
    <h1 className="small">Hi, there. Let&apos;s talk!</h1>
    <p className="gray">
      Fill the form or email us at
      <span className="email">moc&#x2024;ezameed@ofni </span>.
    </p>
    <ContactForm />
    <FindUs title={TITLE} addresses={ADDRESSES} image={IMAGE} />
  </main>
);

export default ContactUs;
