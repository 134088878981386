import React from 'react';
import PropTypes from 'prop-types';

import { imageShape } from '../../utils/prop_shapes';
import TextWrapper from '../TextWrapper';

const DesktopShowcase = ({ desktopSection }) => (
  <section className="desktop-showcase section-padding bg-light full-width">
    <div className="flex-wrapper">
      <TextWrapper text={desktopSection} />
      <img alt={desktopSection.image.alt} src={desktopSection.image.source} />
    </div>
  </section>
);
export default DesktopShowcase;

DesktopShowcase.propTypes = {
  desktopSection: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: imageShape.isRequired,
  }),
};
