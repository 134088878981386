import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Waypoint from 'react-waypoint';

import { imageShape, galleryShape } from '../../utils/prop_shapes';
import TextWrapper from '../TextWrapper';

export const GALLERY_NUMBER = {
  teamWork: 1,
  collabs: 2,
  clientProblem: 3,
  knowProduct: 4,
  solutions: 5,
};

export default class ProjectGallery extends Component {
  state = {
    arrayOfClasses: ['left', 'active', 'right'],
    startAnimating: false,
  };

  galleryWrapperRef = React.createRef();

  handleClickPhoto = (clickedPhoto) => {
    const { arrayOfClasses } = this.state;
    const auxArray = [...arrayOfClasses];
    if (arrayOfClasses[clickedPhoto] === 'left') {
      const [left, active, right] = arrayOfClasses;
      auxArray[0] = active;
      auxArray[1] = right;
      auxArray[2] = left;
    } else if (arrayOfClasses[clickedPhoto] === 'right') {
      const [left, active, right] = arrayOfClasses;
      auxArray[0] = right;
      auxArray[1] = left;
      auxArray[2] = active;
    }

    this.setState({
      arrayOfClasses: auxArray,
    });
  };

  renderDesktopGallery = () => {
    const { galleryPhotos } = this.props;
    const { arrayOfClasses } = this.state;

    /* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
    return (
      <div className="desktop-gallery">
        {galleryPhotos.map((photo, index) => (
          <img
            src={photo.source}
            alt={photo.alt}
            key={index}
            className={arrayOfClasses[index]}
            onClick={() => this.handleClickPhoto(index)}
          />
        ))}
      </div>
    );
    /* eslint-enable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
  };

  renderExtraGallery = (classModifier) => {
    const { extraPhotos } = this.props;

    return (
      <div className={`extra-gallery${classModifier || ''}`}>
        {extraPhotos.map((photo, index) => (
          <img
            src={photo.source}
            alt={photo.alt}
            key={index}
            className={`steps step${index}`}
          />
        ))}
      </div>
    );
  };

  handleScroll = (direction) => {
    const galleryWrapper = this.galleryWrapperRef.current;

    if (direction === 'right') {
      galleryWrapper.scrollBy({
        behavior: 'smooth',
        left: 500,
      });
    } else {
      galleryWrapper.scrollBy({
        behavior: 'smooth',
        left: -500,
      });
    }
  };

  renderArrows = () => (
    <div className="arrows-wrapper">
      <button
        type="button"
        className="arrow-left black"
        onClick={() => this.handleScroll('left')}
        aria-label="Previous"
      >
        <span />
      </button>
      <button
        type="button"
        className="arrow-right black"
        onClick={() => this.handleScroll('right')}
        aria-label="Next"
      >
        <span />
      </button>
    </div>
  );

  handleGalleries = () => {
    const { projectName, galleryPhotos, galleryNumber, extraPhotos } =
      this.props;
    const insideClass = classNames('', {
      'mobile-gallery full-width-mobile': projectName === 'storytrail',
      'binder-gallery full-width-mobile':
        projectName === 'binderstudio' &&
        galleryNumber === GALLERY_NUMBER.teamWork,
      'binder-gallery full-width second-gallery':
        projectName === 'binderstudio' &&
        galleryNumber === GALLERY_NUMBER.collabs,
      'ezfx-gallery':
        projectName === 'ezfxbroker' &&
        galleryNumber === GALLERY_NUMBER.clientProblem,
      'ezfx-gallery second-gallery':
        projectName === 'ezfxbroker' &&
        galleryNumber === GALLERY_NUMBER.knowProduct,
      'ezfx-gallery third-gallery':
        projectName === 'ezfxbroker' &&
        galleryNumber === GALLERY_NUMBER.solutions,
    });
    const singleImage = galleryPhotos.length === 1;

    return (
      <div className={insideClass} ref={this.galleryWrapperRef}>
        <div className="flex-wrapper">
          {singleImage ? (
            <Waypoint onEnter={this.startAnimation} bottomOffset="20%">
              <img
                src={galleryPhotos[0].source}
                alt={galleryPhotos[0].alt}
                key={`image-wrapper-${0}`}
              />
            </Waypoint>
          ) : (
            galleryPhotos.map((photo, index) => (
              <img
                src={photo.source}
                alt={photo.alt}
                key={`image-wrapper-${index}`}
              />
            ))
          )}
          {projectName === 'ezfxbroker' &&
            extraPhotos &&
            this.renderExtraGallery()}
        </div>
      </div>
    );
  };

  startAnimation = () => this.setState({ startAnimating: true });

  render() {
    const { projectName, galleryNumber, galleryText, extraPhotos } = this.props;
    const galleryClass = classNames('section-padding', {
      'storytrail-gallery-wrapper full-width': projectName === 'storytrail',
      'binderstudio-gallery-wrapper full-width-mobile':
        galleryNumber === GALLERY_NUMBER.teamWork &&
        projectName === 'binderstudio',
      'binderstudio-gallery-wrapper full-width':
        galleryNumber === GALLERY_NUMBER.collabs &&
        projectName === 'binderstudio',
      'ezfxbroker-gallery-wrapper':
        galleryNumber === GALLERY_NUMBER.clientProblem &&
        projectName === 'ezfxbroker',
      'ezfxbroker-gallery-wrapper second-gallery':
        galleryNumber === GALLERY_NUMBER.knowProduct &&
        projectName === 'ezfxbroker',
      'ezfxbroker-gallery-wrapper bg-light third-gallery full-width':
        galleryNumber === GALLERY_NUMBER.solutions &&
        projectName === 'ezfxbroker',
    });
    const { startAnimating } = this.state;
    const paletteWrapper = classNames(galleryClass, {
      'loading-animation': startAnimating,
    });

    return (
      <section className={paletteWrapper}>
        {projectName === 'ezfxbroker' ? (
          <div className="gallery-text-wrapper">
            {Object.values(galleryText).map((content, index) => (
              <TextWrapper key={index} text={content} />
            ))}
          </div>
        ) : (
          Object.values(galleryText).map((content, index) => (
            <TextWrapper key={index} text={content} />
          ))
        )}
        {this.handleGalleries()}
        {this.renderArrows()}
        {projectName === 'storytrail' && this.renderDesktopGallery()}
        {projectName === 'ezfxbroker' &&
          extraPhotos &&
          this.renderExtraGallery('-mobile')}
      </section>
    );
  }
}

ProjectGallery.propTypes = {
  galleryPhotos: PropTypes.arrayOf(imageShape),
  extraPhotos: PropTypes.arrayOf(imageShape),
  galleryText: PropTypes.arrayOf(galleryShape).isRequired,
  projectName: PropTypes.string.isRequired,
  galleryNumber: PropTypes.number,
};
