import React from 'react';
import PropTypes from 'prop-types';

import { imageShape } from '../../utils/prop_shapes';
import TextWrapper from '../TextWrapper';

const EZFXBrokerDescription = ({ ezfxIntroduction }) => {
  const renderFirstColumn = (feature, index) => (
    <React.Fragment key={`feature-${index}`}>
      <div className="description-wrapper" key={feature.title}>
        <TextWrapper text={feature} />
      </div>
      <div className="overflow-wrapper full-width-mobile">
        <div className="image-wrapper">
          <img
            src={feature.image.source}
            className="mobile-hide"
            alt={feature.image.alt}
          />
          <img
            src={feature.image_mobile}
            className="desktop-hide"
            alt={feature.image.alt}
          />
        </div>
      </div>
    </React.Fragment>
  );

  const renderSecondColumn = (feature, index) => (
    <React.Fragment key={`feature-${index}`}>
      <img src={feature.image.source} alt={feature.image.alt} />
    </React.Fragment>
  );

  return (
    <section className="ezfx-features-section full-width">
      <div className="features-wrapper column-1-mobile full-width-mobile">
        {ezfxIntroduction.map((feature, index) =>
          renderFirstColumn(feature, index),
        )}
      </div>
      <div className="grid-wrapper mobile-hide">
        <div className="features-wrapper column-1">
          {ezfxIntroduction.map((feature, index) =>
            renderFirstColumn(feature, index),
          )}
        </div>
        <div className="features-wrapper column-2">
          {ezfxIntroduction.map((feature, index) =>
            renderSecondColumn(feature, index),
          )}
        </div>
      </div>
    </section>
  );
};

export default EZFXBrokerDescription;

EZFXBrokerDescription.propTypes = {
  ezfxIntroduction: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      image: imageShape.isRequired,
      image_mobile: PropTypes.string,
    }),
  ),
};
