import React from 'react';

import LandingSection from '../LandingSection';
import HomepageSection from '../HomepageSection';
import CallToAction from '../CallToAction';
import SiteMetadata from '../SiteMetadata';

import brythmHighlight from '../../assets/projects/brythm/brythm_highlight.svg';
import popHighlight from '../../assets/projects/pop/pop_highlight.png';
import binderHighlight from '../../assets/projects/binder/binder_highlight.svg';
import stHighlight from '../../assets/projects/storytrail/st_highlight.svg';
import ogPreview from '../../assets/og_preview.png';

const PROJECTS_CONTENT = {
  title: 'Projects',
  subtitle: 'We bring your ideas to life.',
  description: [
    'We’ve been building software for ourselves and for clients, and we do it with passion. ' +
      'We have worked with people from all corners of the world and solved problems in multiple areas ' +
      '— healthcare, fintech, music industry, tourism, you name it.',
    'Take a look at our portfolio highlights:',
  ],
};

const PROJECTS = [
  {
    slug: 'brythm',
    image: {
      alt: 'Brythm, iOS and Android app',
      source: brythmHighlight,
    },
  },
  {
    slug: 'iampop',
    image: {
      alt: 'I am pop, web app',
      source: popHighlight,
    },
  },
  {
    slug: 'binderstudio',
    image: {
      alt: 'Binder Studio, web app',
      source: binderHighlight,
    },
  },
  {
    slug: 'storytrail',
    image: {
      alt: 'Storytrail, iOS app',
      source: stHighlight,
    },
  },
];

const CALL_TO_ACTION_TEXT = {
  title: 'Get in touch',
  description:
    'We are quite interested in listening to your ' +
    'new project ideas or how we can add value to your business. ' +
    'If you want to know more about the team and how we can work with you, contact us.',
};

const SERVICES_CONTENT = {
  title: 'Services',
  subtitle: 'We’ll help you figure it out.',
  description: [
    'Not sure what you need from us? We can help you plan your product from the ground up, or integrate your own technical team to help you expand ' +
      'your current business. ' +
      'Whatever challenges you bring our way, we’ll work together to find the best solution for you and your users.',
    "Let's build something together!",
  ],
};

// meta data props
const metadata = {
  keywords: [
    'Deemaze',
    'Deemaze Software',
    'Digital Product Development',
    'Digital Services Devlopment',
    'App Development',
    'Product Development',
    'Software Devolpment',
    'Apps',
    'Mobile App',
    'Mobile App Development',
    'Web',
    'Web Design',
    'Software',
    'iOS',
    'Android',
    'Website',
    'build an MVP',
  ],
  description:
    "Get acquainted with what we do, and reach out when you're ready to meet us.",
  siteUrl: 'https://deemaze.com',
  sitePrefix: 'Deemaze Software',
  social: ogPreview,
};

const Homepage = () => (
  <main className="homepage full-width">
    <SiteMetadata {...metadata} isHome />
    <LandingSection />
    <HomepageSection
      content={PROJECTS_CONTENT}
      projects={PROJECTS}
      direction="rtl"
    />
    <HomepageSection content={SERVICES_CONTENT} direction="ltr" />
    <CallToAction
      background="white"
      text={CALL_TO_ACTION_TEXT}
      types={['contacts']}
    />
  </main>
);

export default Homepage;
