import React, { Component } from 'react';
import classNames from 'classnames/bind';
import { Element } from 'react-scroll';

import PhotoGallery from '../PhotoGallery';
import customFetch from '../../utils/network_utils';

const firstGalleryText =
  "Together, we've been designing and developing software for clients, partners and ourselves. In the spare time, we've organized several events and attended awesome conferences.";

const secondGalleryText =
  'And we also spend quality time getting to know each other outside the office!';

const thirdGalleryText = '';

const numbers = {
  1: 'One',
  2: 'Two',
  3: 'Three',
  4: 'Four',
  5: 'Five',
  6: 'Six',
  7: 'Seven',
  8: 'Eight',
  9: 'Nine',
  10: 'Ten',
};

const galleryTextFor = (galleryNumber) => {
  switch (galleryNumber) {
    case 2:
      return secondGalleryText;
    case 3:
      return thirdGalleryText;
    default:
      return null;
  }
};

export default class GallerySection extends Component {
  state = {
    secondGalleryVisible: false,
    thirdGalleryVisible: false,
    photos: [],
    isSecondGaleryAbove: false,
    fadeOutSecondGallery: false,
    fadeOutThirdGallery: false,
  };

  componentDidMount() {
    this.getPhotos();
  }

  async getPhotos() {
    const photos = await customFetch('/api/photos');
    this.setState({ photos });
  }

  setSecondGalleryVisible = (value) =>
    this.setState({ secondGalleryVisible: value });

  setisSecondGaleryAbove = (value) =>
    this.setState({ isSecondGaleryAbove: value });

  setThirdGalleryVisible = (value) =>
    this.setState({ thirdGalleryVisible: value });

  setFadeOutSecondGallery = (value) =>
    this.setState({ fadeOutSecondGallery: value });

  setFadeOutThirdGallery = (value) =>
    this.setState({ fadeOutThirdGallery: value });

  setFadeOut = (gallery, value) => {
    if (gallery === 2) {
      this.setState({ fadeOutSecondGallery: value });
    } else {
      this.setState({ fadeOutThirdGallery: value });
    }
  };

  renderText = () => {
    const {
      secondGalleryVisible,
      thirdGalleryVisible,
      isSecondGaleryAbove,
      fadeOutSecondGallery,
      fadeOutThirdGallery,
    } = this.state;
    const secondGalleryTextClass = classNames('mobile-hide', {
      'red-paragraph':
        secondGalleryVisible && !thirdGalleryVisible && !isSecondGaleryAbove,
      'gray-paragraph': thirdGalleryVisible || isSecondGaleryAbove,
      'fade-out': fadeOutSecondGallery,
    });
    const thirdGalleryTextClass = classNames('mobile-hide', {
      'red-paragraph': thirdGalleryVisible,
      'fade-out': fadeOutThirdGallery,
    });
    const birthday = new Date(2015, 0, 5);
    const age = new Date(Date.now() - birthday.getTime()).getFullYear() - 1970;

    return (
      <div className="text-section">
        <div className="text-wrapper">
          <h2>{`${numbers[age]} busy years`}</h2>
          <p>{firstGalleryText}</p>
          <p className={secondGalleryTextClass}>{secondGalleryText}</p>
          <p className={thirdGalleryTextClass}>{thirdGalleryText}</p>
        </div>
      </div>
    );
  };

  render() {
    const { photos } = this.state;

    return (
      <Element name="How" className="gallery-section full-width">
        {this.renderText()}

        {photos &&
          photos.map((photo, index) => (
            <PhotoGallery
              key={`photo-gallery-${index}`}
              galleryNumber={index + 1}
              gallery={photo}
              setSecondGalleryVisible={
                index + 1 === 2 ? this.setSecondGalleryVisible : undefined
              }
              setThirdGalleryVisible={
                index + 1 === 3 ? this.setThirdGalleryVisible : undefined
              }
              galleryText={galleryTextFor(index + 1)}
              setisSecondGaleryAbove={
                (index + 1 === 2 || index + 1 === 3) &&
                this.setisSecondGaleryAbove
              }
              setFadeOut={
                index + 1 === 2 || index + 1 === 3 ? this.setFadeOut : undefined
              }
            />
          ))}
      </Element>
    );
  }
}
