import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export default class TextInput extends Component {
  state = {
    active: false,
  };

  handleChange = (event) =>
    this.setState({ active: event.target.value.length > 0 });

  render() {
    const { id, label, error, ...inputProps } = this.props;
    const { active } = this.state;
    const labelClasses = classNames({ active, error });

    return (
      <React.Fragment>
        <label htmlFor={id} className={labelClasses}>
          <span>{label}</span>
          <input id={id} onChange={this.handleChange} {...inputProps} />
        </label>
        {error && <span className="error">{error}</span>}
      </React.Fragment>
    );
  }
}

TextInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
};
