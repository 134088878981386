import React from 'react';
import PropTypes from 'prop-types';

let isClicked = false;
const mySlider = React.createRef();
const myImage = React.createRef();
let newPosition;
const SLIDER_OFFSET = 6;
const SLIDER_SAFETY_MARGIN = 5;
const SMALL_STEP = 3;
const BIG_STEP = 10;
const KEY_CODES = {
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
  PAGE_UP: 33,
  PAGE_DOWN: 34,
  END: 35,
  HOME: 36,
};

const updateSliderPosition = () => {
  if (newPosition < SLIDER_SAFETY_MARGIN) {
    newPosition = SLIDER_SAFETY_MARGIN;
  } else if (newPosition > 100 - SLIDER_SAFETY_MARGIN) {
    newPosition = 100 - SLIDER_SAFETY_MARGIN;
  }
  const node = mySlider.current;
  const image = myImage.current;
  node.style.left = `${newPosition}%`;
  image.style.width = `${newPosition}%`;
  node.setAttribute('aria-valuenow', newPosition);
};

const handleMove = (typeOfInteraction) => (e) => {
  let mousePosition;
  const node = mySlider.current;
  if (typeOfInteraction === 'mouse') {
    mousePosition = e.pageX;
  } else {
    mousePosition = e.changedTouches[0].pageX;
  }

  if (isClicked) {
    node.focus();
    const parentsOffset =
      node.parentNode.offsetLeft + node.parentNode.parentNode.offsetLeft;
    newPosition = (
      (100 * (mousePosition - parentsOffset)) /
      node.parentNode.offsetWidth
    ).toFixed(2);
    updateSliderPosition();
  }
};

const handleMouseDown = () => {
  isClicked = true;
};

const handleMouseUp = () => {
  isClicked = false;
};

const handleKeyboard = (e) => {
  const node = mySlider.current;
  const currentPosition =
    (100 * node.offsetLeft) / node.parentNode.offsetWidth + SLIDER_OFFSET;

  switch (e.keyCode) {
    case KEY_CODES.LEFT:
    case KEY_CODES.DOWN:
      newPosition = currentPosition - SMALL_STEP;
      break;

    case KEY_CODES.RIGHT:
    case KEY_CODES.UP:
      newPosition = currentPosition + SMALL_STEP;
      break;

    case KEY_CODES.PAGE_DOWN:
      newPosition = currentPosition - BIG_STEP;
      break;

    case KEY_CODES.PAGE_UP:
      newPosition = currentPosition + BIG_STEP;
      break;

    case KEY_CODES.HOME:
      newPosition = 0;
      break;

    case KEY_CODES.END:
      newPosition = 100;
      break;

    default:
      break;
  }
  updateSliderPosition();
};

/* eslint-disable jsx-a11y/no-static-element-interactions */
const BrythmDevices = ({ devices }) => (
  <section
    className="brythm-devices full-width"
    onMouseUp={handleMouseUp}
    onTouchEnd={handleMouseUp}
    onMouseMove={handleMove('mouse')}
    onTouchMove={handleMove('touch')}
  >
    <div className="content-wrapper">
      <div>
        <div className="image-slider">
          <div className="image-wrapper">
            <img src={devices.images.android} alt="Menu layout for Android." />
          </div>
          <div className="image-wrapper" ref={myImage}>
            <img src={devices.images.iphone} alt="Menu layout for iPhone." />
          </div>
          <div className="slider-track">
            <button
              className="slider"
              ref={mySlider}
              type="button"
              role="slider"
              aria-valuemax="100"
              aria-valuemin="0"
              aria-valuenow="50"
              onMouseDown={handleMouseDown}
              onTouchStart={handleMouseDown}
              onKeyDown={handleKeyboard}
            >
              <div className="dot" />
            </button>
          </div>
        </div>
      </div>
      <div>
        {devices.title}
        <p className="gray">{devices.description}</p>
      </div>
    </div>
  </section>
);
/* eslint-enable jsx-a11y/no-static-element-interactions */

export default BrythmDevices;

BrythmDevices.propTypes = {
  devices: PropTypes.shape({
    title: PropTypes.shape.isRequired,
    description: PropTypes.string.isRequired,
    images: PropTypes.shape({
      iphone: PropTypes.string.isRequired,
      android: PropTypes.string.isRequired,
    }).isRequired,
  }),
};
