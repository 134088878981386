import React from 'react';

const Cover = () => (
  <div className="cover-wrapper full-width">
    <div className="title">
      <h1 className="small">Deemaze</h1>
      <h1 className="small gray">Here&apos;s all you need to know about us.</h1>
    </div>
    <div className="cover-image-wrapper full-width" />
  </div>
);

export default Cover;
