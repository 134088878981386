import React from 'react';

import SocialNetworks from '../SocialNetworks';
import DeemazeLogo from '../DeemazeLogo';
import deemazeNetworks from '../../utils/deemaze_networks';

const Footer = () => (
  <footer className="full-width">
    <div className="footer-wrapper">
      <a href="/" className="link-home">
        <DeemazeLogo color="red" />
      </a>
      <div className="social-icons">
        <SocialNetworks networks={deemazeNetworks} />
      </div>
    </div>
  </footer>
);

export default Footer;
