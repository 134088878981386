import React from 'react';
import PropTypes from 'prop-types';

import TextWrapper from '../TextWrapper';

const renderGallery = (artistsInformation) => (
  <div className="artists-gallery full-width">
    <div className="scroll-wrapper full-width">
      <div className="overflow-wrapper">
        {artistsInformation.map((artist, index) => (
          <div className="artist-wrapper" key={index}>
            <img alt="" src={artist.asset.file_path} />
            <div className="artist-info">
              <p>{artist.name}</p>
              <a
                href={`https://${artist.link}`}
                target="_blank"
                rel="noreferrer"
              >
                {artist.link}
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

const PopArtists = ({ artistsGalleryText, artistsInformation }) => (
  <React.Fragment>
    <section className="pop-artists section-padding full-width">
      <TextWrapper text={artistsGalleryText} />
      {renderGallery(artistsInformation)}
    </section>
    <span className="separator" />
  </React.Fragment>
);

export default PopArtists;

PopArtists.propTypes = {
  artistsGalleryText: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }),
  artistsInformation: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      asset: PropTypes.shape({
        file_path: PropTypes.string.isRequired,
      }).isRequired,
    }),
  ),
};
