import React from 'react';
import PropTypes from 'prop-types';
import { Element } from 'react-scroll';

import MediumPost from '../MediumPost';
import TextWrapper from '../TextWrapper';
import deemazeNetworks from '../../utils/deemaze_networks';

const WHATS_NEW = {
  title: "What's new",
  description:
    'The truth is: we are always up to something. Check our latest updates and hottest news:',
};

const MediumPostsWrapper = ({ posts }) => (
  <Element
    name="whatsnew"
    className="medium-posts-section full-width section-padding"
  >
    <div className="whats-new-wrapper">
      <TextWrapper text={WHATS_NEW} />
    </div>
    <div className="social-platforms-wrapper">
      {deemazeNetworks.map((network, index) => (
        <a href={network.link_url} target="_blank" key={index} rel="noreferrer">
          {network.link_type}
        </a>
      ))}
    </div>
    <div className="medium-posts-wrapper">
      {posts.map((post) => (
        <MediumPost
          key={post.id}
          title={post.title}
          date={post.date}
          link={post.link}
          imageLink={post.image_link}
          writer={post.writer}
        />
      ))}
    </div>
  </Element>
);

export default MediumPostsWrapper;

MediumPostsWrapper.propTypes = {
  posts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      image_link: PropTypes.string.isRequired,
      writer: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
};
