import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

const renderPreviewPhoto = (project) => {
  const altText = `${project.name} - ${project.project_type.join(', ')}`;
  return (
    <React.Fragment>
      <img className="desktop-hide" src={project.assets.mobile} alt={altText} />
      <img className="mobile-hide" src={project.assets.desktop} alt={altText} />
    </React.Fragment>
  );
};

const ProjectPreview = ({ project }) => {
  const pnClassName = classNames({
    left: project.text_position === 'left',
    right: project.text_position === 'right',
    'text-alternate': project.text_color === 'white',
  });

  return (
    <Link
      to={`/projects/${project.name_slug}`}
      className="project-preview"
      key={project.id}
    >
      <div>
        {renderPreviewPhoto(project)}
        <p className={`project-name ${pnClassName}`}>{project.name}</p>
      </div>
    </Link>
  );
};

export default ProjectPreview;

ProjectPreview.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    name_slug: PropTypes.string.isRequired,
    text_position: PropTypes.string.isRequired,
    text_color: PropTypes.string.isRequired,
    asset: PropTypes.shape({
      file_path: PropTypes.string.isRequired,
    }).isRequired,
  }),
};
