import React from 'react';
import PropTypes from 'prop-types';

import { imageShape } from '../../utils/prop_shapes';
import TextWrapper from '../TextWrapper';

const renderFeatures = (sections) =>
  sections.map((section) => (
    <div className="mobile-feature" key={section.title}>
      <p className="white">{section.title}</p>
      <p className="small">{section.description}</p>
      <img src={section.image.source} alt={section.image.alt} />
    </div>
  ));

const BinderInspiration = ({ inspirationIntro, inspirationSections }) => (
  <section className="binder-inspiration section-padding full-width">
    <TextWrapper text={inspirationIntro} />
    <div className="mobile-features-grid">
      {renderFeatures(inspirationSections)}
    </div>
  </section>
);

export default BinderInspiration;

BinderInspiration.propTypes = {
  inspirationSections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      image: imageShape.isRequired,
    }),
  ),
  inspirationIntro: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }),
};
