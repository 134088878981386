import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import gsap from 'gsap';

import { imageShape } from '../../utils/prop_shapes';
import PortfolioHighlights from '../PortfolioHighlights';

export default class HomePageSection extends Component {
  titleRef = React.createRef();

  sectionRef = React.createRef();

  tick = false;

  componentDidMount() {
    const { direction } = this.props;
    const title = this.titleRef.current;
    let progressOffset = 250;

    if (direction === 'ltr') {
      progressOffset *= -1;
    }
    this.currentAnimation = gsap.timeline({
      paused: true,
      smoothChildTiming: true,
    });
    this.currentAnimation.from(title, { x: progressOffset, duration: 0.3 });
    window.addEventListener('scroll', this.handleScrollDebounce);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScrollDebounce);
  }

  handleScrollDebounce = () => {
    if (this.tick) return this;
    this.tick = !this.tick;
    this.handleScroll();
    return this;
  };

  handleScroll() {
    const section = this.sectionRef.current;
    const targetScrollHeight = section.offsetHeight + window.innerHeight;
    const scrolledHeight =
      window.scrollY + window.innerHeight - section.offsetTop;
    const progress = (scrolledHeight / targetScrollHeight).toFixed(2);
    this.currentAnimation.progress(progress);
    this.tick = !this.tick;
    return this;
  }

  renderText = (content) => (
    <React.Fragment>
      <h1 className="white" ref={this.titleRef}>
        {content.title}
      </h1>
      <h2 className="white">{content.subtitle}</h2>
      <div className="text-wrapper">
        {content.description.map((paragraph, index) => (
          <p className="white large" key={index}>
            {paragraph}
          </p>
        ))}
      </div>
    </React.Fragment>
  );

  render() {
    const { content, projects, direction } = this.props;
    return (
      <div
        className="homepage-section full-width section-padding"
        ref={this.sectionRef}
      >
        {this.renderText(content, direction)}
        {projects ? (
          <PortfolioHighlights projects={projects} />
        ) : (
          <div className="button-wrapper">
            <Link to="/services" className="button">
              Services
            </Link>
          </div>
        )}
      </div>
    );
  }
}

HomePageSection.propTypes = {
  direction: PropTypes.string,
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    description: PropTypes.arrayOf(PropTypes.string.isRequired),
  }),
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string.isRequired,
      image: imageShape.isRequired,
    }),
  ),
};
