import PropTypes from 'prop-types';

export const deemazerShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  user_name: PropTypes.string.isRequired,
  id: PropTypes.integer,
  asset: PropTypes.shape({
    file_path: PropTypes.string.isRequired,
  }).isRequired,
  social_links: PropTypes.arrayOf(
    PropTypes.shape({
      link_type: PropTypes.string.isRequired,
      link_url: PropTypes.string.isRequired,
    }),
  ),
});

export const projectsShape = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.string,
  order: PropTypes.number.isRequired,
  made_with: PropTypes.arrayOf(PropTypes.string).isRequired,
  our_role: PropTypes.arrayOf(PropTypes.string).isRequired,
  asset: PropTypes.shape({
    file_path: PropTypes.string,
  }),
  name_slug: PropTypes.string,
});

export const listOfFactsShape = PropTypes.shape({
  fact_description: PropTypes.string.isRequired,
  deemazers_percentages: PropTypes.arrayOf(
    PropTypes.shape({
      deemazer_id: PropTypes.number.isRequired,
      fact_percentage: PropTypes.number.isRequired,
    }),
  ),
});

export const paletteShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  hex_code: PropTypes.string.isRequired,
});

export const imageShape = PropTypes.shape({
  alt: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
});

export const galleryShape = PropTypes.shape({
  title: PropTypes.string,
  description: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.shape(),
  ]),
});

export const contentPropTypes = PropTypes.shape({
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.shape(),
  ]),
});
