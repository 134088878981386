import React from 'react';
import PropTypes from 'prop-types';

const LinkCardImages = ({ type }) => (
  <React.Fragment>
    {type === 'contacts' ? (
      <svg className="contacts-image" viewBox="0 0 2000 2000" version="1.1">
        <g
          id="circles-contacts"
          fill="none"
          fillRule="nonzero"
          stroke="#FFF"
          strokeWidth="4"
        >
          <circle
            id="Oval-a1"
            cx="1000"
            cy="1000"
            r="15"
            vectorEffect="non-scaling-stroke"
            stroke="#F52D4E"
            fill="#FFF"
          />
          <circle
            id="Oval-a2"
            cx="1000"
            cy="1000"
            r="1000"
            vectorEffect="non-scaling-stroke"
            stroke="#F52D4E"
          />
          <circle
            id="Oval-a3"
            cx="1000"
            cy="1000"
            r="1000"
            vectorEffect="non-scaling-stroke"
            stroke="#F52D4E"
          />
          <circle
            id="Oval-a4"
            cx="1000"
            cy="1000"
            r="1000"
            vectorEffect="non-scaling-stroke"
            stroke="#F52D4E"
          />
        </g>
      </svg>
    ) : (
      <svg className="services-image" viewBox="0 0 2000 2000" version="1.1">
        <g
          id="circles-services"
          fill="none"
          fillRule="nonzero"
          stroke="#FFF"
          strokeWidth="25"
          vectorEffect="non-scaling-stroke"
        >
          <circle id="Oval-b1" cx="500" cy="1000" r="700" />
          <circle id="Oval-b2" cx="1500" cy="1000" r="700" />
          <line x1="945" y1="550" x2="1055" y2="550" />
          <line x1="880" y1="700" x2="1120" y2="700" />
          <line x1="830" y1="850" x2="1170" y2="850" />
          <line x1="815" y1="1000" x2="1185" y2="1000" />
          <line x1="830" y1="1150" x2="1170" y2="1150" />
          <line x1="880" y1="1300" x2="1120" y2="1300" />
          <line x1="945" y1="1450" x2="1055" y2="1450" />
        </g>
      </svg>
    )}
  </React.Fragment>
);

export default LinkCardImages;

LinkCardImages.propTypes = {
  type: PropTypes.string.isRequired,
};
