import React, { Component } from 'react';
import Waypoint from 'react-waypoint';
import classNames from 'classnames';

import video1 from '../../assets/projects/brythm/notifications_1.mp4';
import video2 from '../../assets/projects/brythm/notifications_2.mp4';
import score from '../../assets/projects/brythm/score.svg';

const VIDEOS_SRC = [video1, video2];

export default class BrythmProgress extends Component {
  state = {
    startAnimating: false,
  };

  videosRef = React.createRef();

  componentDidMount() {
    this.handleVideosPosition();
  }

  handleVideosPosition = () => {
    const element = this.videosRef.current;
    element.scrollLeft = (element.scrollWidth - element.clientWidth) / 2;
  };

  startAnimation = () => this.setState({ startAnimating: true });

  featuresExamples = () => {
    const FEATURES = [
      {
        title: 'Track your progress',
        description:
          'In each session, a score is calculated with a unique algorithm developed specially for Brythm. This information is kept and tracked, so both you and the research team can examine your progress over time.',
        image: {
          src: score,
          alt: 'Score screen with latest score and history.',
        },
      },
      {
        title: "Don't you, forget about me.",
        description:
          "For better results and for the purpose of the study, it's important to make a session and register your blood pressure readings everyday. But adding something new to your routine isn't always easy, so you can set reminders for the most convenient time!",
      },
    ];

    return (
      <React.Fragment>
        {FEATURES.map((feature, index) => {
          const featureClass = classNames('feature section-padding', {
            separator: index === 0,
            'full-width-mobile': index !== 0,
          });
          return (
            <div className={featureClass} key={index}>
              <div className="text-wrapper">
                {index === 1 ? (
                  <Waypoint onEnter={this.startAnimation} bottomOffset="75%">
                    <h2>{feature.title}</h2>
                  </Waypoint>
                ) : (
                  <h2>{feature.title}</h2>
                )}
                <p className="gray">{feature.description}</p>
              </div>
              {index === 0 && (
                <React.Fragment>
                  <img src={feature.image.src} alt={feature.image.alt} />
                </React.Fragment>
              )}
              {index === 1 && this.renderVideos()}
            </div>
          );
        })}
      </React.Fragment>
    );
  };

  renderVideos = () => {
    const { startAnimating } = this.state;
    const videoClass = classNames('', {
      'loading-animation': startAnimating,
    });

    return (
      <div className="overflow-wrapper full-width-mobile">
        <div className="videos-wrapper" ref={this.videosRef}>
          {VIDEOS_SRC.map((video, index) => (
            <div key={`video-${index}`}>
              <video
                className={videoClass}
                autoPlay
                playsInline
                muted
                loop
                src={video}
              />
            </div>
          ))}
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="features-wrapper full-width">
        {this.featuresExamples()}
      </div>
    );
  }
}
