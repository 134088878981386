import React from 'react';
import PropTypes from 'prop-types';

const MediumPost = (props) => {
  const { imageLink, link, title, writer } = props;
  const postWriter = `by ${writer}`;

  return (
    <a href={link} target="_blank" className="post" rel="noreferrer">
      <p className="post-writer gray">{postWriter}</p>
      <p className="post-title">{title}</p>
      <div className="post-image-wrapper">
        <img className="post-image" src={imageLink} alt="" />
      </div>
      <span className="read-on-medium">Read on Medium</span>
    </a>
  );
};

MediumPost.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  imageLink: PropTypes.string.isRequired,
  writer: PropTypes.string.isRequired,
};

export default MediumPost;
