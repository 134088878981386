import React from 'react';
import PropTypes from 'prop-types';

const DeemazeLogo = ({ color }) => (
  <svg
    width="128px"
    height="32px"
    viewBox="0 -5 885 230"
    version="1.1"
    className={`deemaze-logo ${color}`}
  >
    <title>Deemaze</title>
    <path
      className="deemaze-icon"
      strokeWidth="1"
      stroke="#FFFFFF"
      fill="#FFFFFF"
      d="M190.304205,115.046903 C192.638449,111.852598 193.08464,107.357102 189.465678,103.737258 C173.438678,87.7092584 157.410678,71.6822584 141.382678,55.6542584 C133.167678,47.4402584 120.439678,60.1672584 128.654678,68.3832584 C142.505857,82.234438 156.357784,96.0871114 170.209812,109.938051 C158.347428,121.80196 146.484637,133.664435 134.622249,145.526692 C120.715013,131.620548 106.80853,117.713198 92.9028,103.8066 C84.6868,95.5936 71.9588,108.3206 80.1738,116.5356 C96.2018,132.5646 112.2298,148.5926 128.2578,164.6196 C130.234934,166.596975 132.4736,167.360898 134.618807,167.266402 C136.765935,167.362426 139.007096,166.598804 140.9863,164.6196 C157.0133,148.5926 173.0413,132.5646 189.0683,116.5356 C189.550794,116.053224 189.961043,115.555272 190.304205,115.046903 Z M86.5432426,53.0791121 C84.3954018,52.9822326 82.1533244,53.7452986 80.1733,55.7246 C64.1463,71.7526 48.1183,87.7796 32.0913,103.8066 C30.1123589,105.786023 29.3487346,108.02716 29.4445778,110.174218 C29.3495504,112.320019 30.1132258,114.559426 32.0909,116.5371 C48.1179,132.5651 64.1459,148.5921 80.1739,164.6211 C88.3889,172.8341 101.1169,160.1081 92.9019,151.8911 C78.9960686,137.987004 65.0894845,124.079144 51.1828007,110.172745 C62.9678442,98.3886398 74.7528161,86.6032703 86.537319,74.8186243 C100.444748,88.725201 114.351524,102.631224 128.2583,116.538 C136.4733,124.75 149.2013,112.024 140.9863,103.809 C124.9583,87.782 108.9303,71.753 92.9023,55.726 C90.9257647,53.7492241 88.687856,52.9851649 86.5432426,53.0791121 Z M110.989,221.976 C49.691,221.976 0,172.285 0,110.986 C0,49.694 49.69,0 110.989,0 C172.287,0 221.977,49.694 221.977,110.986 C221.977,172.283 172.286,221.976 110.989,221.976 Z"
    />
    <g
      className="deemaze-name"
      transform="translate(262.000000, 35.000000)"
      strokeWidth="2"
      stroke="#FFFFFF"
      fill="#FFFFFF"
    >
      <path d="M14.333,100.1665 C14.333,105.5965 18.611,109.8735 24.041,109.8735 L52.341,109.8735 C53.328,109.8735 54.151,109.2155 54.151,108.2295 L54.151,63.3115 C53.822,58.8675 50.367,55.4135 46.254,54.9195 L24.042,54.9195 C18.612,54.9195 14.334,59.1975 14.334,64.6275 L14.334,100.1665 L14.333,100.1665 Z M23.712,41.2635 L42.798,41.2635 C48.063,41.2635 52.176,43.7305 54.151,46.5275 L54.151,1.9385 C54.151,0.9525 54.974,0.1295 55.961,0.1295 L68.465,0.1295 L68.465,118.2665 C68.465,121.2275 66.162,123.5315 63.2,123.5315 L24.041,123.5315 C10.713,123.5315 0.019,112.8365 0.019,99.5085 L0.019,65.2855 C0.018,51.9585 10.384,41.2635 23.712,41.2635 L23.712,41.2635 Z" />
      <path d="M112.3965,54.7553 C106.9655,54.7553 102.6885,57.8813 102.6885,64.4633 L102.6885,73.3483 C102.6885,74.3343 103.3455,74.9923 104.3335,75.1583 L142.6705,75.1583 C143.6585,75.1583 144.4815,74.3343 144.4815,73.3483 L144.4815,64.4633 C144.4815,57.8813 141.3555,54.7553 134.9385,54.7553 L112.3965,54.7553 Z M88.3735,99.5083 L88.3735,64.6263 C88.3735,49.3243 99.0685,41.2623 112.3955,41.2623 L134.4425,41.2623 C147.7705,41.2623 158.4635,49.3243 158.4635,64.6263 L158.4635,83.5473 C158.4635,86.3443 158.1345,88.6493 154.1865,88.6493 L109.1035,88.6493 C106.4705,88.6493 104.1675,87.4963 102.6865,85.5243 L102.6865,100.1663 C102.6865,105.5963 106.9635,109.8753 112.3945,109.8753 L153.1995,109.8753 C154.1865,109.8753 155.0085,110.6973 155.0085,111.6843 L155.0085,123.5313 L112.3955,123.5313 C99.0685,123.5293 88.3735,112.8363 88.3735,99.5083 L88.3735,99.5083 Z" />
      <path d="M202.561,54.7553 C197.129,54.7553 192.852,57.8813 192.852,64.4633 L192.852,73.3483 C192.852,74.3343 193.51,74.9923 194.498,75.1583 L232.834,75.1583 C233.823,75.1583 234.645,74.3343 234.645,73.3483 L234.645,64.4633 C234.645,57.8813 231.52,54.7553 225.102,54.7553 L202.561,54.7553 Z M178.539,99.5083 L178.539,64.6263 C178.539,49.3243 189.233,41.2623 202.561,41.2623 L224.608,41.2623 C237.936,41.2623 248.629,49.3243 248.629,64.6263 L248.629,83.5473 C248.629,86.3443 248.299,88.6493 244.352,88.6493 L199.27,88.6493 C196.635,88.6493 194.332,87.4963 192.852,85.5243 L192.852,100.1663 C192.852,105.5963 197.129,109.8753 202.561,109.8753 L243.366,109.8753 C244.352,109.8753 245.174,110.6973 245.174,111.6843 L245.174,123.5313 L202.561,123.5313 C189.233,123.5293 178.539,112.8363 178.539,99.5083 L178.539,99.5083 Z" />
      <path d="M368.4106,65.2856 L368.4106,123.5316 L355.9066,123.5316 C354.9186,123.5316 354.0946,122.7096 354.0946,121.7216 L354.0946,64.4626 C354.0946,59.1976 349.8186,54.7546 344.3876,54.7546 L322.9986,54.7546 C324.6446,55.7406 325.9596,57.3876 325.9596,59.3616 L325.9596,123.5296 L313.4556,123.5296 C312.4676,123.5296 311.6446,122.7076 311.6446,121.7196 L311.6446,56.5646 C311.6446,55.5786 310.8226,54.7556 309.8346,54.7556 L284.9886,54.7556 C284.1666,54.7556 283.5086,55.4136 283.3426,56.2366 L283.3426,121.7216 C283.3426,122.7076 282.5216,123.5316 281.5336,123.5316 L269.0276,123.5316 L269.0276,51.9586 C269.0276,46.0356 273.7996,41.2636 279.7226,41.2636 L344.3856,41.2636 C357.7156,41.2636 368.4106,51.9586 368.4106,65.2856" />
      <path d="M408.8872,109.5454 L436.8582,109.5454 C437.8442,109.5454 438.5042,108.7214 438.5042,107.7344 L438.5042,81.5724 C437.6802,83.0534 436.0352,83.8774 434.2272,84.0414 L408.8872,84.0414 C403.4572,84.0414 399.1802,88.3184 399.1802,93.7484 L399.1802,99.8364 C399.1782,105.2664 403.4572,109.5454 408.8872,109.5454 M452.6532,64.2974 L452.6532,118.2644 C452.6532,121.2274 450.3502,123.5294 447.3872,123.5294 L445.5762,123.5294 L438.3362,123.5294 L408.8852,123.5294 C395.5592,123.5294 384.8642,112.8364 384.8642,99.5084 L384.8642,94.4064 C384.8642,81.0784 395.5592,70.3854 408.8852,70.3854 L436.6922,70.3854 C437.6782,70.3854 438.5022,69.5634 438.5022,68.5744 L438.5022,64.4624 C438.5022,59.1974 435.0472,54.7544 428.6312,54.7544 L395.8872,54.7544 C394.9012,54.7544 394.0782,53.9314 394.0782,52.9434 L394.0782,41.2634 L428.6312,41.2634 C441.9572,41.2634 452.6532,49.3244 452.6532,64.2974" />
      <path d="M533.1099,121.7211 C533.1099,122.7071 532.2879,123.5321 531.2989,123.5321 L469.1059,123.5321 C468.1199,123.5321 467.2959,122.7091 467.2959,121.7211 L467.2959,115.3031 C467.2959,112.1781 468.4479,109.3791 470.2559,107.2421 L514.8459,56.4011 C515.0099,56.2371 515.1739,55.9071 515.1739,55.5781 C515.1739,54.9201 514.6819,54.4261 514.0239,54.4261 L471.5749,54.4261 L471.5749,42.9081 C471.5749,41.9211 472.3969,41.2631 473.3829,41.2631 L530.1509,41.2631 C531.1349,41.2631 531.9599,41.9211 531.9599,42.9081 L531.9599,52.1221 C531.9599,55.2481 530.8089,58.0451 528.9989,60.1841 L486.7139,108.2291 C486.2189,108.7211 486.2189,109.3791 486.7139,109.8731 C486.8779,110.2031 487.2059,110.2031 487.3719,110.2031 L533.1119,110.2031 L533.1119,121.7211 L533.1099,121.7211 Z" />
      <path d="M572.106,54.7553 C566.676,54.7553 562.399,57.8813 562.399,64.4633 L562.399,73.3483 C562.399,74.3343 563.057,74.9923 564.043,75.1583 L602.381,75.1583 C603.368,75.1583 604.192,74.3343 604.192,73.3483 L604.192,64.4633 C604.192,57.8813 601.065,54.7553 594.649,54.7553 L572.106,54.7553 Z M548.085,99.5083 L548.085,64.6263 C548.085,49.3243 558.78,41.2623 572.106,41.2623 L594.155,41.2623 C607.481,41.2623 618.174,49.3243 618.174,64.6263 L618.174,83.5473 C618.174,86.3443 617.846,88.6493 613.899,88.6493 L568.815,88.6493 C566.182,88.6493 563.879,87.4963 562.399,85.5243 L562.399,100.1663 C562.399,105.5963 566.676,109.8753 572.106,109.8753 L612.911,109.8753 C613.899,109.8753 614.721,110.6973 614.721,111.6843 L614.721,123.5313 L572.108,123.5313 C558.78,123.5293 548.085,112.8363 548.085,99.5083 L548.085,99.5083 Z" />
    </g>
  </svg>
);

export default DeemazeLogo;

DeemazeLogo.propTypes = {
  color: PropTypes.string.isRequired,
};
