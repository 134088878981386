import React from 'react';
import PropTypes from 'prop-types';

import DeviceLabPalette from '../DeviceLabPalette';
import { paletteShape } from '../../utils/prop_shapes';
import TextWrapper from '../TextWrapper';

const DeviceLabBranding = ({ brandingContent, palette }) => (
  <section className="devicelab-branding section-padding">
    <TextWrapper text={brandingContent} />
    <DeviceLabPalette palette={palette} />
    <div className="devicelab-logo">
      {brandingContent.logo.map((logo, index) => (
        <div className="logo-wrapper" key={index}>
          <img src={logo} alt="Device Lab logo, a simplified rocket." />
        </div>
      ))}
      <div className="devicelab-typography">
        {brandingContent.typography.map((typography, index) => (
          <img
            src={typography}
            alt="Fonts used in the project: Rufina and Work Sans."
            key={index}
          />
        ))}
      </div>
    </div>
  </section>
);

export default DeviceLabBranding;

DeviceLabBranding.propTypes = {
  brandingContent: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    logo: PropTypes.arrayOf(PropTypes.string).isRequired,
    typography: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
  palette: PropTypes.arrayOf(paletteShape).isRequired,
};
