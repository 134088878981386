import React from 'react';
import PropTypes from 'prop-types';

const Arrow = ({ onClick, direction }) => (
  <button
    type="button"
    className={`arrow-${direction}`}
    onClick={onClick}
    aria-label={direction === 'left' ? 'Previous' : 'Next'}
  >
    <span />
  </button>
);

export default Arrow;

Arrow.propTypes = {
  onClick: PropTypes.func,
  direction: PropTypes.string,
};
