import React from 'react';

import SocialNetworks from '../SocialNetworks';
import deemazeNetworks from '../../utils/deemaze_networks';

const MenuFooter = () => (
  <div className="icons-list">
    <SocialNetworks networks={deemazeNetworks} />
  </div>
);

export default MenuFooter;
