import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { imageShape } from '../../utils/prop_shapes';

const PortfolioHighlights = ({ projects }) => (
  <div className="portfolio-highlights">
    {projects.map((project) => (
      <Link
        to={`/projects/${project.slug}`}
        className="highlight"
        key={project.slug}
      >
        <img src={project.image.source} alt={project.image.alt} />
      </Link>
    ))}
    <Link to="/projects" className="button">
      Explore
    </Link>
  </div>
);

export default PortfolioHighlights;

PortfolioHighlights.propTypes = {
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string.isRequired,
      image: imageShape.isRequired,
    }),
  ),
};
