import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Waypoint from 'react-waypoint';
import classNames from 'classnames';

const CareerOpening = ({ opening }) => {
  const [showDetails, setShowDetails] = useState(false);
  const [playAnimation, setPlayAnimation] = useState(false);
  const [maxHeight, setMaxHeight] = useState();

  const ref = useRef();

  const openingClasses = classNames('career-opening', {
    animate: playAnimation,
  });

  const handleAnimationEnd = () => {
    if (!maxHeight) {
      setMaxHeight(ref.current.scrollHeight);
    }
  };

  const detailsStyle = showDetails ? { maxHeight, maringBottom: '3.2rem' } : {};

  return (
    <Waypoint
      onEnter={() => setPlayAnimation(true)}
      bottomOffset="30%"
      fireOnRapidScroll
    >
      <div className={openingClasses} onAnimationEnd={handleAnimationEnd}>
        <img src={opening.image.source} alt={opening.image.alt} />
        <div className="opening-text">
          <h3 className="title">{opening.title}</h3>
          <p className="description">{opening.description}</p>
          <div ref={ref} className="details" style={detailsStyle}>
            {opening.job_specs
              ? opening.job_specs.map((jobSpec) => (
                  <div key={jobSpec.title} className="details-list">
                    <h2>{jobSpec.title}</h2>
                    <ul>
                      {jobSpec.job_spec_items.map((item) => (
                        <li key={item.id}>{item.copy}</li>
                      ))}
                    </ul>
                  </div>
                ))
              : null}
            <p className="cta">
              Did we grab your attention? If you&apos;re interested in applying
              for a position then drop us a message! No need to be formal.{' '}
              <a className="btn-link" href="#contact-us">
                Apply Now
              </a>
            </p>
          </div>
          <button
            type="button"
            className="btn-link"
            onClick={() => setShowDetails(!showDetails)}
          >
            {showDetails ? 'Read less' : 'Read More'}
          </button>
        </div>
      </div>
    </Waypoint>
  );
};

CareerOpening.propTypes = {
  opening: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.shape({
      source: PropTypes.string,
      alt: PropTypes.string,
    }),
    job_specs: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        job_spec_items: PropTypes.arrayOf(
          PropTypes.shape({
            copy: PropTypes.string,
          }),
        ),
      }),
    ),
  }),
};

export default CareerOpening;
