import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Hamburguer = (props) => {
  const { toggleMenu, whiteburguer, hasScrolledProjectHeader } = props;
  const white = toggleMenu || (whiteburguer && !hasScrolledProjectHeader);
  const burguerClass = classNames('burguer-icon', {
    'hover-menu': toggleMenu,
  });

  return (
    <svg
      width="39px"
      height="39px"
      viewBox="0 0 39 39"
      version="1.1"
      className={burguerClass}
    >
      <title>{toggleMenu ? 'Exit menu' : 'Open menu'}</title>
      <g
        id="Icon/menu"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <rect
          id="top"
          x="16"
          y={toggleMenu ? '19' : '12'}
          fill={white ? 'white' : '#121314'}
          width="23"
          height="2"
        />
        <rect
          id="middle"
          x="21"
          y="19"
          fill={white ? 'white' : '#121314'}
          width="18"
          height="2"
        />
        <rect
          id="bottom"
          x="11"
          y={toggleMenu ? '19' : '26'}
          fill={white ? 'white' : '#121314'}
          width="28"
          height="2"
        />
      </g>
    </svg>
  );
};

export default Hamburguer;

Hamburguer.propTypes = {
  toggleMenu: PropTypes.bool.isRequired,
  whiteburguer: PropTypes.bool.isRequired,
  hasScrolledProjectHeader: PropTypes.bool.isRequired,
};
