import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import MenuList from '../MenuList';
import MenuFooter from '../MenuFooter';
import NavList from '../NavList';

export default class Menu extends Component {
  state = {
    menuAnimationEnd: false,
  };

  handleMenuAnimationEnd = (e) => {
    if (e.animationName === 'menu-slide') {
      this.setState({ menuAnimationEnd: true });
    }
    if (e.animationName === 'menu-slide-up') {
      this.setState({ menuAnimationEnd: false });
    }
  };

  render() {
    const {
      projects,
      toggleMenu,
      firstClick,
      setProjectsHeaderOptions,
      headerOptions,
      setOptionAndVerifyValue,
      verifyOptionValue,
      cancelActiveIndex,
    } = this.props;

    const classname = classNames('menu-wrapper full-width', {
      'animation-in': toggleMenu,
      'animation-out': !toggleMenu && firstClick,
    });

    const { menuAnimationEnd } = this.state;

    return (
      <div className={classname} onAnimationEnd={this.handleMenuAnimationEnd}>
        {toggleMenu && (
          <React.Fragment>
            <NavList
              headerOptions={headerOptions}
              toggleMenu={toggleMenu}
              setOptionAndVerifyValue={setOptionAndVerifyValue}
              verifyOptionValue={verifyOptionValue}
              cancelActiveIndex={cancelActiveIndex}
              menuAnimationEnd={menuAnimationEnd}
            />
            <MenuList
              projects={projects}
              setProjectsHeaderOptions={setProjectsHeaderOptions}
            />
            <MenuFooter />
          </React.Fragment>
        )}
      </div>
    );
  }
}

Menu.propTypes = {
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ),
  toggleMenu: PropTypes.bool.isRequired,
  firstClick: PropTypes.bool.isRequired,
  setProjectsHeaderOptions: PropTypes.func.isRequired,
  headerOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  setOptionAndVerifyValue: PropTypes.func.isRequired,
  verifyOptionValue: PropTypes.func.isRequired,
  cancelActiveIndex: PropTypes.bool.isRequired,
};
