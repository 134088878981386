import React from 'react';
import PropTypes from 'prop-types';

import BrythmSession from '../BrythmSession';
import ProjectHeader from '../ProjectHeader';
import BrythmDevices from '../BrythmDevices';
import ProjectInfo from '../ProjectInfo';
import BrythmProgress from '../BrythmProgress';
import { projectsShape } from '../../utils/prop_shapes';

import iphoneMenu from '../../assets/projects/brythm/iPhoneMenu.jpg';
import androidMenu from '../../assets/projects/brythm/AndroidMenu.jpg';
import cover from '../../assets/projects/brythm/cover_brythm.svg';

import SiteMetadata from '../SiteMetadata';

// meta data props
const baseMetadata = {
  description:
    'App to manage daily breathing sessions to lower blood pressure.',
  siteUrl: 'https://deemaze.com/projects/brythm',
  sitePrefix: 'Brythm',
};

const DEVICES = {
  title: (
    <h2>
      Brythm is on
      <strong> your </strong>
      phone.
    </h2>
  ),
  description:
    'It doesn’t matter what OS the user owns. Brythm provides similar experiences on iPhone and Android, adapted to the user’s device.',
  images: {
    iphone: iphoneMenu,
    android: androidMenu,
  },
};

const Brythm = ({ project }) => (
  <main className="brythm-wrapper full-width">
    <SiteMetadata
      {...baseMetadata}
      keywords={project.keywords}
      social={project.assets.social ? project.assets.social : ''}
    />
    <ProjectHeader project={project} coverImageUrl={cover} whiteText />
    <div className="brythm-description">
      <h2>About Brythm:</h2>
      <p className="gray">
        Brythm was born out of a study being conducted by Bournemouth
        University, that intends to find the effects that slow breathing
        exercises have on blood pressure.
      </p>
      <p className="gray">
        The Brythm app provides the user with a personalised training program
        that adapts the breathing rate according to the individual’s physiology.
        This allows study subjects to take part in the research remotely.
      </p>
    </div>
    <BrythmSession />
    <BrythmProgress />
    <BrythmDevices devices={DEVICES} />
    <ProjectInfo
      project={project}
      madeWith={project.made_with}
      listOfTasks={project.our_role}
      linkToProject={project.link}
    />
  </main>
);

export default Brythm;

Brythm.propTypes = {
  project: PropTypes.shape(projectsShape),
};
