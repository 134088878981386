import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Waypoint from 'react-waypoint';
import classNames from 'classnames';

import { imageShape } from '../../utils/prop_shapes';
import TextWrapper from '../TextWrapper';

import deviceHolder from '../../assets/projects/lab/holder.png';

export default class DeviceLabResponsive extends Component {
  state = {
    startAnimating: false,
  };

  startAnimation = () => this.setState({ startAnimating: true });

  render() {
    const { copy, layouts } = this.props;
    const { startAnimating } = this.state;
    const layoutsWrapper = classNames('layouts-wrapper', {
      'loading-animation': startAnimating,
    });

    return (
      <section className="devicelab-responsive full-width section-padding">
        <TextWrapper text={copy} />
        <Waypoint onEnter={this.startAnimation} bottomOffset="30%">
          <div className={layoutsWrapper}>
            {layouts.map((layout, index) => (
              <div key={index}>
                <img src={layout.source} alt={layout.alt} />
                <img src={deviceHolder} alt="" />
              </div>
            ))}
          </div>
        </Waypoint>
      </section>
    );
  }
}

DeviceLabResponsive.propTypes = {
  copy: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }),
  layouts: PropTypes.arrayOf(imageShape).isRequired,
};
