import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export default class FileInput extends Component {
  state = {
    active: false,
    filename: undefined,
  };

  handleChange = (event) => {
    const fileInput = event.target;
    const active = fileInput.files.length > 0;
    let filename;

    if (active) {
      filename = fileInput.files[0].name;
    }

    this.setState({ active, filename });
  };

  render() {
    const { id, label, filenameLabelRef, error, ...inputProps } = this.props;
    const { active, filename } = this.state;
    const labelClasses = classNames('file', { active, error });

    return (
      <React.Fragment>
        <label htmlFor={id} className={labelClasses}>
          <span>{label}</span>
          <input
            type="file"
            id={id}
            onChange={this.handleChange}
            {...inputProps}
          />
          <span ref={filenameLabelRef}>{filename}</span>
        </label>
        {error && <span className="error">{error}</span>}
      </React.Fragment>
    );
  }
}

FileInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  filenameLabelRef: PropTypes.string,
};
