import React, { Component } from 'react';
import classNames from 'classnames';

import TextInput from '../TextInput';
import TextArea from '../TextArea';
import FileInput from '../FileInput';

const handleResponse = async (response) => {
  if (!response.ok) {
    const json = await response.json();
    const error = {
      ...json,
      status: response.status,
      statusText: response.statusText,
    };

    throw error;
  }
};

export default class ContactForm extends Component {
  state = {
    errors: {},
    success: false,
    loading: false,
  };

  formRef = React.createRef();

  filenameLabelRef = React.createRef();

  spamInputTextRef = React.createRef();

  spamInputHiddenRef = React.createRef();

  handleError = (errors) =>
    this.setState({ errors, success: false, loading: false });

  handleSuccess = () => {
    this.clearForm();
    this.setState({
      success: true,
      errors: {},
      loading: false,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ success: false, loading: true });
    const spamInputText = this.spamInputTextRef.current;
    const spamInputHidden = this.spamInputHiddenRef.current;

    if (spamInputText.value === '' && spamInputHidden.value === '') {
      fetch('api/contacts', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
        body: new FormData(e.target),
      })
        .then(handleResponse)
        .then(this.handleSuccess)
        .catch((errors) => this.handleError(errors.contact_errors));
    } else {
      this.handleError([]);
    }
  };

  clearForm() {
    this.formRef.current.reset();
    this.filenameLabelRef.current.textContent = '';
  }

  render() {
    const { errors, success, loading } = this.state;
    const formClass = classNames('contact-form section-padding', { loading });

    return (
      <React.Fragment>
        <form
          onSubmit={this.handleSubmit}
          className={formClass}
          ref={this.formRef}
        >
          <TextInput
            label="Your name"
            error={errors.name && errors.name[0]}
            disabled={loading}
            id="name"
            type="text"
            name="contact[name]"
          />
          <TextInput
            label="Your email"
            error={errors.email && errors.email[0]}
            disabled={loading}
            id="email"
            type="email"
            name="contact[email]"
          />
          <TextArea
            label="How can we help you?"
            error={errors.message_body && errors.message_body[0]}
            disabled={loading}
            id="message_body"
            type="textarea"
            rows="1"
            name="contact[message_body]"
          />
          <FileInput
            label="Add an attachment."
            filenameLabelRef={this.filenameLabelRef}
            error={errors.attachment && errors.attachment[0]}
            disabled={loading}
            id="file"
            name="contact[attachment]"
          />
          <label className="visuallyhidden" htmlFor="spam_one">
            I&apos;m a robot.
            <input
              type="text"
              name="spam_one"
              id="spam_one"
              tabIndex="-1"
              autoComplete="off"
              ref={this.spamInputTextRef}
            />
          </label>
          <input
            type="hidden"
            name="spam_two"
            autoComplete="off"
            ref={this.spamInputHiddenRef}
          />
          <input
            type="submit"
            value="Send"
            className="gray"
            disabled={loading}
          />
        </form>
        {success && (
          <div className="alert full-width">
            <p>
              Thank you for reaching out to us. We&apos;ll get back to you as
              soon as possible!
            </p>
          </div>
        )}
      </React.Fragment>
    );
  }
}
