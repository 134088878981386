import React from 'react';
import PropTypes from 'prop-types';

import EZFXBrokerPalette from '../EZFXBrokerPalette';
import { paletteShape } from '../../utils/prop_shapes';
import TextWrapper from '../TextWrapper';

const EZFXBrokerBranding = ({ brandingContent, palette }) => (
  <section className="ezfx-branding section-padding">
    <EZFXBrokerPalette palette={palette} />
    <TextWrapper text={brandingContent} />
  </section>
);

export default EZFXBrokerBranding;

EZFXBrokerBranding.propTypes = {
  brandingContent: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    logo: PropTypes.arrayOf(PropTypes.string),
    typography: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
  palette: PropTypes.arrayOf(paletteShape).isRequired,
};
