import React, { Component } from 'react';

import Cover from '../Cover';
import TeamGallery from '../TeamGallery';
import MediumPostsWrapper from '../MediumPostsWrapper';
import customFetch from '../../utils/network_utils';
import GallerySection from '../GallerySection';
import SiteMetadata from '../SiteMetadata';
import CallToAction from '../CallToAction';

import opengraphPreview from '../../assets/og_preview.png';

// meta data props
const metadata = {
  keywords: [
    'Deemaze',
    'Deemaze Software',
    'Digital Product Development',
    'Digital Services Devlopment',
    'App Development',
    'Product Development',
    'Software Devolpment',
    'Apps',
    'Mobile App',
    'Mobile App Development',
    'Web',
    'Web Design',
    'Software',
    'iOS',
    'Android',
    'Website',
    'build an MVP',
    'About',
  ],
  description: "This is Deemaze, here's all you need to know about us.",
  siteUrl: 'https://deemaze.com/about',
  sitePrefix: 'About',
  social: opengraphPreview,
};

const CALL_TO_ACTION_ABOUT = {
  title: 'Looking for a team like ours?',
  subtitle: 'Tell us how we can help.',
  description:
    'Want to make your career with Deemaze? Want some friendly experts to build your product? We’re ready, reach out.',
};

export default class About extends Component {
  state = {
    posts: [],
    deemazers: [],
  };

  componentDidMount() {
    this.getPosts();
    this.getDeemazers();
  }

  async getPosts() {
    const posts = await customFetch('/api/posts');
    this.setState({ posts });
  }

  async getDeemazers() {
    const deemazers = await customFetch('/api/deemazers');
    this.setState({ deemazers });
  }

  render() {
    const { posts, deemazers } = this.state;

    return (
      <main className="about full-width">
        <SiteMetadata {...metadata} />
        <Cover />
        <TeamGallery deemazers={deemazers} />
        <GallerySection />
        <MediumPostsWrapper posts={posts} />
        <CallToAction
          background="white"
          text={CALL_TO_ACTION_ABOUT}
          types={['services', 'contacts']}
        />
      </main>
    );
  }
}
